import { PrimaryButton } from "src/components/UIComponents/Buttons";
import * as S from "./styled";

interface Props {
  buttons: {
    key: string | number;
    displayValue: string;
  }[];
  value?: string | number;
  onChange?: (key: string | number | undefined) => void;
}

export const ButtonsCheckbox = ({ buttons, value, onChange }: Props) => {
  return (
    <S.ButtonsWrapper>
      {buttons.map((b) => (
        <S.StyledButton
          variant={value === b.key ? "contained" : "outlined"}
          key={b.key}
          onClick={() => onChange?.(value === b.key ? undefined : b.key)}
        >
          {b.displayValue}
        </S.StyledButton>
      ))}
    </S.ButtonsWrapper>
  );
};
