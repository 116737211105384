import { Icon, IconButton, SvgIcon } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled("div")(
  ({ theme }) => `
  position: relative;
  border-radius: 6px;
  border: 1px solid ${theme.palette.borders.primary};
  width: 100%;
`
);

export const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  top: 0;
  right: 0;
  padding:  ${theme.spacing(2)};
`
);

export const StyledSvgIcon = styled(Icon)(
  ({ theme }) => `
  position: absolute;
  top: 0;
  left: 0;
  margin-top: ${theme.spacing(2)};
  margin-left: ${theme.spacing(2)};
`
);
