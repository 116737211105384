import { styled } from "@mui/system";
import { PrimaryButton } from "src/components/UIComponents/Buttons";

export const Container = styled("div")(({ theme }) => ``);

export const StyledButton = styled(PrimaryButton)(
  ({ theme }) => `
  margin-top: 0;
  max-height: 50px;
`
);

export const ScannerContainer = styled("div")(
  ({ theme }) => `
  svg {
  display: none;
  }
`
);
