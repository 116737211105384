import { styled } from "@mui/system";

export const Container = styled("div")(
  ({ theme }) => `
  width: 100%;
`
);

export const Divider = styled("div")(
  ({ theme }) => `
  width: 100%;
  height: ${theme.spacing(2)};
`
);
