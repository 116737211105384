import { useTranslation } from "react-i18next";
import CameraIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/Camera.svg";
import { useEffect, useRef, useState } from "react";
import {
  Issue,
  ObservationModalUiProps,
  ReportedMedia,
} from "@checkfirst-ltd/checkfirst-customer-common";
import TrashIcon from "@icons/Trash.svg";
import { ResponsiveModal } from "src/components/UIComponents/ResponsiveModal";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import * as S from "./styled";
import { SvgIcon, TextField } from "@mui/material";
import { ButtonsCheckbox } from "src/components/UIComponents/ButtonCheckbox";
import {
  PrimaryButton,
  SecondaryButton,
} from "src/components/UIComponents/Buttons";
import { MediaThumbnail } from "src/components/UIComponents/MediaThumbnail";
import { MediaCapture } from "src/components/UIComponents/MediaCapture";
import DeleteIcon from "@mui/icons-material/Delete";
interface MediaState {
  type: string;
  timeStamp: string;
  localUri?: string;
  fileSize?: number;
  saved: boolean;
  uri?: string;
  storageKey?: string;
}
interface IssueState {
  description?: string;
  severity?: "HIGH" | "MEDIUM" | "LOW";
  media?: MediaState[];
}

export const ObservationModal = ({
  open,
  value,
  onDelete,
  onClose,
  onSubmit,
}: ObservationModalUiProps) => {
  const { t } = useTranslation();
  const [issueState, setIssueState] = useState<IssueState>({
    description: value?.description ?? "",
    severity: value?.severity ?? undefined,
    media: value?.media?.map((e) => ({ ...e, saved: true })) ?? [],
  });

  useEffect(() => {
    setIssueState({
      ...issueState,
      media: value?.media?.map((e) => ({ ...e, saved: true })) ?? [],
    });
  }, [value?.media]);

  const [openMediaPicker, setOpenMediaPicker] = useState(false);

  const onMediaChange = (
    data: string,
    type: "image" | "video",
    storageKey?: string
  ) => {
    setIssueState({
      ...issueState,
      media: [
        ...(issueState.media ?? []),
        {
          type,
          timeStamp: new Date().toString(),
          localUri: data,
          saved: false,
          storageKey,
        },
      ],
    });
    setOpenMediaPicker(false);
  };

  const onDeletePress = (media: MediaState) => {
    const index =
      issueState.media?.findIndex((e) => e.localUri == media.localUri) ?? -1;
    if (index > -1) {
      let newMedia = [...(issueState.media ?? [])];
      newMedia.splice(index, 1);
      setIssueState({
        ...issueState,
        media: newMedia,
      });
    }
  };

  return (
    <>
      <ResponsiveModal
        open={open}
        width={"464px"}
        title={
          onDelete
            ? t("raiseIssueModal.updateTitle")
            : t("raiseIssueModal.title")
        }
        onClose={onClose}
        closeButton
      >
        <S.Subtitle variant="body2">{t("raiseIssueModal.subtitle")}</S.Subtitle>
        <SpacingDivider $spacing={2} />
        <TextField
          placeholder={t("raiseIssueModal.textboxPlaceholder")}
          variant="standard"
          fullWidth
          value={issueState.description}
          onChange={(e) => {
            setIssueState({ ...issueState, description: e.target.value });
          }}
        />
        <ButtonsCheckbox
          value={issueState.severity}
          onChange={(key) => {
            setIssueState({ ...issueState, severity: key as any });
          }}
          buttons={[
            {
              key: "HIGH",
              displayValue: t("raiseIssueModal.severity.HIGH"),
            },
            {
              key: "MEDIUM",
              displayValue: t("raiseIssueModal.severity.MEDIUM"),
            },
            { key: "LOW", displayValue: t("raiseIssueModal.severity.LOW") },
          ]}
        />
        {issueState.severity && (
          <>
            <SpacingDivider $spacing={2} />
            <S.Subtitle variant="caption">
              {t("raiseIssueModal.mediaLabel")}
            </S.Subtitle>
            <SpacingDivider $spacing={1} />
            <SecondaryButton
              variant="outlined"
              startIcon={
                <SvgIcon
                  component={CameraIcon as any}
                  style={{ width: "16px", height: "16px" }}
                />
              }
              fullWidth={false}
              onClick={() => setOpenMediaPicker(true)}
            >
              {t("raiseIssueModal.imageButtonLabel")}
            </SecondaryButton>
            {(issueState.media?.length ?? 0) > 0 && (
              <SpacingDivider $spacing={1} />
            )}
            <S.MediaContainer>
              {issueState.media?.map((m) => (
                <MediaThumbnail
                  url={m.localUri ?? ""}
                  type={m.type as any}
                  storageKey={m.storageKey}
                  onDeleteClick={() => onDeletePress(m)}
                />
              ))}
            </S.MediaContainer>
          </>
        )}
        <S.ButtonsContainer>
          {onDelete && (
            <PrimaryButton
              variant="contained"
              onClick={() => {
                onDelete();
              }}
            >
              <DeleteIcon />
            </PrimaryButton>
          )}
          <PrimaryButton
            fullWidth
            disabled={!issueState.description?.trim()}
            variant="contained"
            onClick={() => {
              onSubmit(issueState as Issue);
            }}
          >
            {onDelete
              ? t("raiseIssueModal.updateButtonLabel")
              : t("raiseIssueModal.submitButtonLabel")}
          </PrimaryButton>
        </S.ButtonsContainer>
      </ResponsiveModal>
      <MediaCapture
        open={openMediaPicker}
        onClose={() => setOpenMediaPicker(false)}
        onCapture={(data, type, storageKey) =>
          onMediaChange(data, type, storageKey)
        }
      />
    </>
  );
};
