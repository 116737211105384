import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Root } from "src/navigation/root";
import { ThemeProvider } from "@mui/system";
// import { ThemeProvider } from "styled-components";
import checkfirstCommon, {
  AppUtilsProvider,
  useStores,
} from "@checkfirst-ltd/checkfirst-customer-common";
import { theme } from "src/theme/theme";
import { AppUtils } from "src/utils/appUtils";
import { StatefulSnackbar } from "src/components/Toaster";
// If you are using date-fns v3.x, please import the v3 adapter
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useTranslation } from "react-i18next";
import { pt, es, enGB, zhCN, de } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import localforage from "localforage";
import { HelmetProvider } from "react-helmet-async";

const { initLocalization, initStorage, initApiClient, initRootStore } =
  checkfirstCommon;

initLocalization();
localforage.config({
  driver: localforage.INDEXEDDB,
  name: "checkfirst",
  version: 1.0,
  storeName: "keyvaluepairs", // Should be alphanumeric, with underscores.
});
initStorage(localforage as any);
initRootStore();

const { queryClient, QueryClientProvider } = initApiClient({
  apiUrl: process.env.REACT_APP_API_URL as string,
});

const localeMap: any = {
  en: enGB,
  zh: zhCN,
  de: de,
  pt: pt,
  es: es,
};
export const App: React.FC = () => {
  const { i18n } = useTranslation();
  return (
    <HelmetProvider>
      <AppUtilsProvider implementation={AppUtils}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={localeMap[i18n.language]}
          >
            <QueryClientProvider client={queryClient}>
              <BrowserRouter future={{ v7_startTransition: true }}>
                <Root />
                <StatefulSnackbar />
              </BrowserRouter>
            </QueryClientProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AppUtilsProvider>
    </HelmetProvider>
  );
};

export default App;
