import { MutableRefObject, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
import * as S from "./styled";
import { IconButton, SvgIcon } from "@mui/material";
import SignIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/Signature.svg";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useElementDimensions } from "src/hooks/useElementDimensions";
interface SignaturePadProps {
  onReset: (ref?: MutableRefObject<SignatureCanvas | null>) => void;
  onSignEnd: (signature: string) => void;
}

export const SignaturePad: React.FC<SignaturePadProps> = ({
  onReset,
  onSignEnd,
}) => {
  const { t } = useTranslation();
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);
  const { width } = useElementDimensions("signature-container");

  const handleReset = () => {
    sigCanvasRef.current?.clear();
    onReset(sigCanvasRef);
  };

  const handleOnEnd = async () => {
    if (!sigCanvasRef.current?.isEmpty()) {
      const base64 = sigCanvasRef.current?.toDataURL() || "";
      onSignEnd(base64);
    }
  };

  return (
    <S.Container id="signature-container">
      <S.StyledSvgIcon>
        <SvgIcon component={SignIcon as any} />
      </S.StyledSvgIcon>
      <S.StyledIconButton onClick={handleReset}>
        <RefreshOutlinedIcon color="secondary" />
      </S.StyledIconButton>
      <SignatureCanvas
        onEnd={handleOnEnd}
        ref={(ref) => {
          sigCanvasRef.current = ref;
        }}
        canvasProps={{
          height: 160,
          width: width ?? 300,
          className: "sigCanvas",
        }}
      />
    </S.Container>
    // <Grid
    //   item
    //   gap={1}
    //   sx={{
    //     display: "flex",
    //     flexDirection: "column",
    //   }}
    // >
    //   <Grid
    //     flexDirection={"row"}
    //     display={"flex"}
    //     justifyContent={"space-between"}
    //     alignItems={"center"}
    //     alignSelf={"stretch"}
    //   >
    //     <Grid
    //       item
    //       display={"flex"}
    //       alignItems={"center"}
    //       flexDirection={"row"}
    //       gap={0.5}
    //     >
    //       <GestureIcon />
    //       <Typography variant="subtitle1">
    //         {t("signaturePage.signaturePlaceholder")}
    //       </Typography>
    //     </Grid>
    //     <Grid item>
    //       <IconButton onClick={handleReset}>
    //         <RotateRightIcon />
    //       </IconButton>
    //     </Grid>
    //   </Grid>

    // </Grid>
  );
};
