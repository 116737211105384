import {
  SupportedLanguages,
  useSharedUtils,
} from "@checkfirst-ltd/checkfirst-customer-common";
import { useTranslation } from "react-i18next";
import * as S from "./styled";
import { SvgIcon, Typography } from "@mui/material";
import CalendarIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/Calendar.svg";

export const DateLabel = () => {
  const { getTodayDate } = useSharedUtils(process.env.API_URL as string);
  const { i18n } = useTranslation();
  return (
    <S.Container>
      <SvgIcon component={CalendarIcon as any} />
      <Typography variant="body1" color="primary">
        {getTodayDate(undefined, i18n.language as SupportedLanguages)}
      </Typography>
    </S.Container>
  );
};
