import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import { ResponsiveModal } from "src/components/UIComponents/ResponsiveModal";
import * as S from "./styled";

interface Props {
  open: boolean;
  instruction?: string;
  imageURL?: string;
  onClose: () => void;
}

export const InstructionsModal = ({
  open,
  instruction,
  imageURL,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  return (
    <ResponsiveModal
      open={open}
      onClose={onClose}
      title={t("instructionModal.title")}
      closeButton={true}
    >
      {instruction && (
        <>
          <SpacingDivider $spacing={2} />
          <Typography variant="body1" color="primary">
            {instruction}
          </Typography>
        </>
      )}
      {imageURL && (
        <>
          <SpacingDivider $spacing={2} />
          <S.StyledImage src={imageURL} />
        </>
      )}
    </ResponsiveModal>
  );
};
