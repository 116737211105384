import { getBrowser } from "src/utils/browserUtils";

export function validateObjectURL(url: string): Promise<boolean> {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.src = url;

    video.onloadeddata = () => {
      resolve(true); // The URL is valid
      video.remove();
    };

    video.onerror = () => {
      resolve(false); // The URL is invalid
      video.remove();
    };
  });
}

export const getCodecs = () => {
  const types = [
    // "video/webm;codecs=vp9",
    "video/webm;codecs=vp8,opus",
    "video/mp4;codecs=avc1.42E01E,mp4a.40.2",
  ];
  const codec = types.find(
    (e) => MediaSource.isTypeSupported(e) && MediaRecorder.isTypeSupported(e)
  );
  console.log("supported codec is", codec);
  return codec ?? getAllSupportedMimeTypes() ?? types[0];
};

function getAllSupportedMimeTypes(
  ...mediaTypes: ("video" | "audio")[]
): string | undefined {
  if (!mediaTypes.length) mediaTypes.push("video", "audio");
  const CONTAINERS = [
    "webm",
    "ogg",
    "mp3",
    "mp4",
    "x-matroska",
    "3gpp",
    "3gpp2",
    "3gp2",
    "quicktime",
    "mpeg",
    "aac",
    "flac",
    "x-flac",
    "wave",
    "wav",
    "x-wav",
    "x-pn-wav",
    "not-supported",
  ];
  const CODECS = [
    "vp9",
    "vp9.0",
    "vp8",
    "vp8.0",
    "avc1",
    "av1",
    "h265",
    "h.265",
    "h264",
    "h.264",
    "opus",
    "vorbis",
    "pcm",
    "aac",
    "mpeg",
    "mp4a",
    "rtx",
    "red",
    "ulpfec",
    "g722",
    "pcmu",
    "pcma",
    "cn",
    "telephone-event",
    "not-supported",
  ];

  return [
    // ...new Set(
    //   CONTAINERS.flatMap((ext) =>
    //     mediaTypes.flatMap((mediaType) => [`${mediaType}/${ext}`])
    //   )
    // ),
    ...new Set(
      CONTAINERS.flatMap((ext) =>
        CODECS.flatMap((codec) =>
          mediaTypes.flatMap((mediaType) => [
            // NOTE: 'codecs:' will always be true (false positive)
            `${mediaType}/${ext};codecs=${codec}`,
          ])
        )
      )
    ),
    ...new Set(
      CONTAINERS.flatMap((ext) =>
        CODECS.flatMap((codec1) =>
          CODECS.flatMap((codec2) =>
            mediaTypes.flatMap((mediaType) => [
              `${mediaType}/${ext};codecs="${codec1}, ${codec2}"`,
            ])
          )
        )
      )
    ),
  ].find(
    (variation) =>
      MediaSource.isTypeSupported(variation) &&
      MediaRecorder.isTypeSupported(variation)
  );
}

export function cacheVideoBlob(blob: Blob, filename: string) {
  if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: "CACHE_VIDEO_BLOB",
      blob: blob,
      filename: filename,
      contentType: getCodecs(),
    });
  }
}

export function fetchCachedVideo(filename: string) {
  if ("serviceWorker" in navigator) {
    return navigator.serviceWorker.ready
      .then(() => {
        return fetch(`/${filename}`);
      })
      .then((response) => {
        if (response.ok) {
          return response.url;
        } else {
          throw new Error("Video not found in cache");
        }
      })
      .catch((error) => {
        throw error;
      });
  } else
    return Promise.reject(() => {
      throw new Error("Service worker not available");
    });
}

export function fetchVideo(url: string): Promise<string> {
  return fetch(url).then((response) => {
    if (response.ok) {
      return url;
    } else {
      throw new Error("Cannot fetch url");
    }
  });
}

