import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const SecondaryButton = styled(Button)(
  ({ theme }) => `
  border-radius: 20px;
  min-height: 40px;
  padding-inline: ${theme.spacing(2)};
  text-transform: none;
  font-weight: normal;
  `
);


export const PrimaryButton = styled(Button)(
  ({ theme }) => `
  margin-top: ${theme.spacing(2)};
  text-transform: none;
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
`
);
