import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from "@mui/material";

export const StyledAccordionDetails = styled(AccordionDetails)(
  ({ theme }) => `
  border-top: 1px solid ${theme.palette.secondary.main};
  padding: 0;
`
);

export const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme }) => `
  padding: 0;
  .MuiAccordionSummary-content {
    margin: ${theme.spacing(2)} 0;
    display: flex;
    align-items: center;
    gap: ${theme.spacing(2)};
    }
`
);

export const TextContainer = styled("div")(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
`
);

export const Divider = styled("div")(
  ({ theme }) => `
  border-top: 1px solid ${theme.palette.secondary.main};
  width: 100%;
`
);
