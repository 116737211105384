import { styled } from "@mui/system";
import { TimePicker } from "@mui/x-date-pickers";

export const StyledTimePicker = styled(TimePicker)(
  ({ theme }) => `
  min-height: 40px;
  button {
    margin-right: 0;
  }
  .MuiInputBase-root.MuiInput-root {
    height: 100%;
  }
  .Mui-error::after, .Mui-error::before {
    border-bottom-color: ${theme.palette.primary.main} !important;
  }
`
);
