import { Drawer } from "@mui/material";
import * as S from "./styled";

interface Props {
  open: boolean;
  children: any;
  onClose: () => void;
}
export const BottomActionSheet = ({ open, children, onClose }: Props) => {
  return (
    <S.Container
      anchor={"bottom"}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: "10px 10px 0 0",
          backgroundColor: "white",
        },
      }}
    >
      {children}
    </S.Container>
  );
};
