import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { images } from "src/assets/images";
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { BaseContainer } from "src/components/BaseContainer";
import dayjs from "dayjs";
import { SignaturePad } from "src/components/UIComponents/SignaturePad";
import { useCallback, useEffect, useState } from "react";
import {
  useStores,
  useSharedUtils,
  RetryMedia,
} from "@checkfirst-ltd/checkfirst-customer-common";
import { useNavigate } from "react-router-dom";
import { RetryMediaModal } from "src/components/UIComponents/RetryModal";
import { Card } from "src/components/UIComponents/Card";
import { observer } from "mobx-react-lite";
import { DateLabel } from "src/components/UIComponents/DateLabel";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import KeyboardIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/KeyboardOutlined.svg";
import Logo from "../../assets/images/checkfirst-Icon-logo-dark.png";
import { MainLayout } from "src/components/Layouts/MainLayout";
import { PrimaryButton } from "src/components/UIComponents/Buttons";
import * as S from "./styled";
import { useGeoLocation } from "src/hooks/useGeoLocation";
import { Helmet } from "react-helmet-async";

export const Signature: React.FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    reportStore,
    checklistInfoStore,
    formStore,
    mediaStore,
    snackbarStore,
    checksStore,
  } = useStores();
  const { uploadFileToS3, prepareReport } = useSharedUtils(
    process.env.REACT_APP_API_URL ?? ""
  );
  const location = useGeoLocation();

  const [signature, setSignature] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirmPress = async () => {
    try {
      setSubmitted(true);
      if (!mediaStore?.isUploadComplete) return;

      if (location)
        reportStore?.addReportDeviceInformation({
          latitude: location.latitude,
          longitude: location.longitude,
        });

      if (!fullName || !signature) {
        return;
      }
      setLoading(true);
      const fileUrl = await uploadFileToS3(
        signature.split("data:image/png;base64,")[1],
        "image/png"
      );

      reportStore?.addReportSignature({
        name: fullName,
        date: new Date().getTime(),
        imageURL: fileUrl,
      });

      const reportChecklistItems = prepareReport({
        multipleChecklist: checklistInfoStore?.multipleChecklist,
        formState: formStore?.formItems,
      });
      reportStore?.addReportChecklistItems(reportChecklistItems);
      navigate("/success");
    } catch (error) {
      console.log(error);
      snackbarStore.showError(t("signaturePage.error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Checkfirst</title>
      </Helmet>
      <Card
        loading={loading}
        title={t("signaturePage.title")}
        subtitle={t("signaturePage.subtitle")}
        image={<img src={Logo} alt="logo" />}
        body={
          <div>
            <DateLabel />
            <SpacingDivider $spacing={2} />
            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon component={KeyboardIcon as any} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <SpacingDivider $spacing={2} />
            <SignaturePad
              onSignEnd={(data) => {
                setSignature(data);
              }}
              onReset={() => {
                setSignature("");
              }}
            />
            {submitted && !mediaStore?.isUploadComplete && (
              <>
                <SpacingDivider $spacing={2} />
                <S.ErrorContainer>
                  <S.StyledProgress color="error" />
                  <Typography variant="body2" color="error">
                    {t("signaturePage.uploadPendingMessage")}
                  </Typography>
                </S.ErrorContainer>
              </>
            )}
            <PrimaryButton
              variant="contained"
              fullWidth
              onClick={handleConfirmPress}
              disabled={
                !fullName ||
                !fullName?.trim() ||
                !signature ||
                !signature?.trim() ||
                (submitted && !mediaStore?.isUploadComplete)
              }
            >
              {t("signaturePage.confirmButtom")}
            </PrimaryButton>
          </div>
        }
        onBackClick={() => {
          navigate("/checklist");
        }}
        footer={
          <Typography variant="body2" color="primary">
            {t("signaturePage.footer")}
          </Typography>
        }
      />
      <RetryMedia
        renderModal={useCallback(
          (props) => (
            <RetryMediaModal {...props} />
          ),
          []
        )}
      />
    </MainLayout>
  );
});
