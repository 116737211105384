import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment, SvgIcon, TextField } from "@mui/material";
import { SignaturePad } from "src/components/UIComponents/SignaturePad";
import { useState } from "react";
import {
  useStores,
  AnswerUiProps,
} from "@checkfirst-ltd/checkfirst-customer-common";
import { Card } from "src/components/UIComponents/Card";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import KeyboardIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/KeyboardOutlined.svg";
import { PrimaryButton } from "src/components/UIComponents/Buttons";
import * as S from "./styled";
import Signature from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/Signature.svg";
import { ResponsiveModal } from "src/components/UIComponents/ResponsiveModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { theme } from "src/theme/theme";

export const SignatureAnswer = ({
  value,
  onChange,
  onDeleteSignature,
}: AnswerUiProps) => {
  const { t } = useTranslation();
  const { snackbarStore } = useStores();

  const [signature, setSignature] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleAdd = async () => {
    try {
      if (!fullName || !signature) {
        return;
      }
      setLoading(true);

      onChange?.(signature, undefined, fullName);
    } catch (error) {
      console.log(error);
      snackbarStore.showError(t("signaturePage.error"));
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  return (
    <>
      <S.StyledInput
        value={fullName}
        placeholder={t("signatureAnswer.placeholder")}
        onClick={() => setOpen(true)}
        endAdornment={
          <>
            {value && (
              <img
                alt="signature"
                src={value}
                style={{
                  maxHeight: "40px",
                  marginBottom: theme.spacing(0.5),
                }}
              />
            )}
            <S.StyledInputAdornment position="end" $number={true}>
              <IconButton
                aria-label="open signature modal"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <SvgIcon
                  component={Signature as any}
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: `-${theme.spacing(0.5)}`,
                  }}
                />
              </IconButton>
            </S.StyledInputAdornment>
          </>
        }
      />

      <ResponsiveModal
        open={open}
        onClose={() => {
          setOpen(false);
          !value && setFullName("");
        }}
      >
        <Card
          loading={loading}
          title={t("signatureAnswer.title")}
          subtitle={t("signatureAnswer.subtitle")}
          body={
            <div>
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon component={KeyboardIcon as any} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <SpacingDivider $spacing={2} />
              <SignaturePad
                onSignEnd={(data) => {
                  setSignature(data);
                }}
                onReset={(ref) => {
                  ref && ref.current?.clear();
                  setSignature("");
                }}
              />
              <PrimaryButton
                sx={{
                  width: "25%",
                }}
                variant="contained"
                onClick={() => onDeleteSignature?.(signature)}
                disabled={!value}
              >
                <DeleteIcon />
              </PrimaryButton>
              <PrimaryButton
                variant="contained"
                sx={{
                  width: "70%",
                  marginLeft: "5%",
                }}
                onClick={handleAdd}
                disabled={
                  !fullName ||
                  !fullName?.trim() ||
                  !signature ||
                  !signature?.trim()
                }
              >
                {t("raiseIssueModal.submitButtonLabel")}
              </PrimaryButton>
            </div>
          }
        />
      </ResponsiveModal>
    </>
  );
};
