import { useStores } from "@checkfirst-ltd/checkfirst-customer-common";
import localforage from "localforage";

export const useIndexedDb = () => {
  const storeMediaChunk = (key: string, chunk: ArrayBuffer) => {
    console.log("stored item at ", `${key}`, chunk);

    return localforage.setItem(`${key}`, chunk).catch(() => {
      //retry one more time
      return localforage.setItem(`${key}`, chunk);
    });
  };

  const storeMedia = (key: string, blob: Blob) => {
    console.log("stored item at ", `${key}`, blob);

    return localforage.setItem(`${key}`, blob).catch(() => {
      //retry one more time
      return localforage.setItem(`${key}`, blob);
    });
  };

  const readMediaChunk = (key: string) => {
    console.log("reading item at ", `${key}`);
    return localforage.getItem(`${key}`).catch(() => {
      return localforage.getItem(`${key}`);
    });
  };

  const readMedia = (key: string) => {
    console.log("reading item at ", `${key}`);
    return localforage
      .getItem(`${key}`)
      .then((blob: any) => URL.createObjectURL(blob));
  };

  return {
    storeMedia,
    readMedia,
    storeMediaChunk,
    readMediaChunk,
  };
};
