import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { images } from "src/assets/images";
import { QrCodeScanner } from "src/components/UIComponents/QrCodeScanner";
import {
  SupportedLanguages,
  useSharedUtils,
  useStores,
} from "@checkfirst-ltd/checkfirst-customer-common";
import { TranslationSheet } from "src/components/UIComponents/TranslationSheet";
import { Card } from "src/components/UIComponents/Card";
import { MainLayout } from "src/components/Layouts/MainLayout";
import * as S from "./styled";
import { Link } from "@mui/material";
import { PrimaryButton } from "src/components/UIComponents/Buttons";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Checks } from "src/components/Checks/Index";
import { Helmet } from "react-helmet-async";

export const Welcome: React.FC = observer(() => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { snackbarStore, rootStore, checksStore } = useStores();
  const { parseUrl } = useSharedUtils(process.env.API_URL as string);
  const [url, setUrl] = useState<string>("");
  let [searchParams] = useSearchParams();
  let { checklistId } = useParams();

  useEffect(() => {
    //check if there is query params
    const checklist_Id = checklistId ?? searchParams.get("checklistId");
    const token = searchParams.get("token");

    if (token && checklist_Id) {
      checksStore.setActiveToken(token);
      rootStore.reinitializeReportStores();
      navigate(`/begin-report?checklistId=${checklist_Id}&token=${token}`);
    }
  }, []);

  const handleOpenPress = () => {
    submit(url);
  };

  const onScan = (url: string) => {
    submit(url);
  };

  const submit = (url: string) => {
    const parseResult = parseUrl(url, process.env.REACT_APP_DOMAIN as string);
    if (!parseResult) {
      snackbarStore.showError(t("homePage.invalidLinkError"));
      return;
    }
    const { token, checklistId } = parseResult;
    if (token) checksStore.setActiveToken(token);
    rootStore.reinitializeReportStores();
    navigate(`/begin-report?checklistId=${checklistId}&token=${token}`);
  };

  return (
    <MainLayout position="relative">
      <Helmet>
        <title>Checkfirst</title>
      </Helmet>
      <Card
        variant="secondary"
        title={t("homePage.title")}
        loading={!checksStore.isHydrated} //make sure checksStore is hydrated before initializing
        subtitle={
          <div>
            <Typography color="secondary" variant="body1" component={"span"}>
              {t("homePage.subtitle")}
            </Typography>
            <Typography variant="body1" component={"span"}>
              <Link href="https://www.checkfirst.ai/" color="secondary">
                Checkfirst.ai
              </Link>
            </Typography>
          </div>
        }
        image={<img src={images.logo} alt="logo" />}
        onTranslatePress={(key) => {
          i18n.changeLanguage(key);
        }}
        footer={
          <div>
            <Typography color="primary" variant="body2" component={"span"}>
              {t("homePage.footerText")}
            </Typography>
            <S.ColoredLink color="tertiary" variant="body2" component={"span"}>
              <Link
                href="https://dashboard.checkfirstapp.com/"
                underline="none"
              >
                {t("homePage.footerLinkText")}
              </Link>
            </S.ColoredLink>
          </div>
        }
        body={
          <S.BodyContainer>
            <QrCodeScanner onScan={onScan} />
            <S.TextBoxContainer>
              <S.StyledInput
                placeholder={t("homePage.textBoxPlaceholder")}
                startAdornment={
                  <InputAdornment position="start">
                    <KeyboardAltOutlinedIcon color="primary" />
                  </InputAdornment>
                }
                fullWidth
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />

              <S.OpenButton
                variant="contained"
                onClick={handleOpenPress}
                disabled={!url}
              >
                {t("homePage.openButton")}
              </S.OpenButton>
            </S.TextBoxContainer>
          </S.BodyContainer>
        }
      />
      <Checks />
    </MainLayout>
  );
});
