import { styled } from "@mui/system";

export const Container = styled("div")(
  ({ theme }) => `
  border: 1px solid ${theme.palette.secondary.main};
  border-radius: 6px;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: ${theme.spacing(2)};
  margin-top: 0;
  width: calc(100% - ${theme.spacing(4)});
  ${theme.breakpoints.up("sm")} {
    max-width: 570px;
    width: 100%;
  }
`
);

export const CardHeader = styled("div")(
  ({ theme }) => `
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  border-bottom: 1px solid ${theme.palette.secondary.main};
`
);

export const CardBody = styled("div")(
  ({ theme }) => `
  padding: 0 ${theme.spacing(2)};
`
);
