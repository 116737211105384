import {
  CircularProgress,
  Input,
  InputAdornment,
  InputAdornmentProps,
} from "@mui/material";
import { styled } from "@mui/system";

export const StyledProgress = styled(CircularProgress)(
  ({ theme }) => `
  width: 14px !important;
  height: 14px !important;
`
);

export const ErrorContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(0.5)};
  align-items: center;
`
);
export const StyledInput = styled(Input)(
  ({ theme }) => `
  & {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    &:hover:not(.Mui-disabled, .Mui-error):before, &:before {
      border-bottom: 2px solid ${theme.palette.borders.primary};
    }
  }
  input {
    width: fit-content;
  }
  width: 100%;
  align-items: flex-end;
  min-height: 40px;
  justify-content: space-between;
`
);

export const StyledInputAdornment = styled(InputAdornment)<
  { $number: boolean } & InputAdornmentProps
>(
  ({ theme, $number }) => `
  margin-bottom: ${theme.spacing($number ? 2 : 1.5)};
`
);
