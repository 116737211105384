import styled from 'styled-components';
import { Checkbox, Input, InputAdornment, InputAdornmentProps } from "@mui/material";

export const Container = styled("div")(
  ({ theme }) => `
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`
);

export const StyledInput = styled(Input)(
  ({ theme }) => `
  & {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    &:hover:not(.Mui-disabled, .Mui-error):before, &:before {
      border-bottom: 2px solid ${theme.palette.borders.primary};
    }
  }
  width: 100%;
  align-items: flex-end;
  min-height: 40px;
`
);

export const StyledInputAdornment = styled(InputAdornment)<
  { $number: boolean } & InputAdornmentProps
>(
  ({ theme, $number }) => `
  margin-bottom: ${theme.spacing($number ? 2 : 1.5)};
`
);

export const StyledChipButton = styled(Checkbox)(
  ({ theme }) => `
  &, &:hover {
    min-height: 40px;
    border-radius: 100px;
  }
  `
);