import { useStores } from "@checkfirst-ltd/checkfirst-customer-common";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIndexedDb } from "src/hooks/useIndexedDb";
import { cacheVideoBlob, getCodecs } from "src/utils/videoUtils";

export const useRecordVideo = (
  onCapture: (
    path: string,
    type: "image" | "video",
    storageKey?: string
  ) => void
) => {
  const webcamRef = useRef<any>(null);
  const mediaRecorderRef = useRef<any>(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [timeStamp, _setTimeStamp] = useState<Date>(new Date());
  const { checksStore } = useStores();
  const timeStampRef = useRef(timeStamp);
  const { storeMedia } = useIndexedDb();

  const setTimeStamp = (value: Date) => {
    timeStampRef.current = value;
    _setTimeStamp(value);
  };

  const handleDataAvailable = ({ data }: { data: Blob }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => {
        return prev.concat(data);
      });
    }
  };

  const handleStartCaptureClick = (timestamp: Date) => {
    setCapturing(true);
    setRecordedChunks([]);
    setTimeStamp(timestamp);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: getCodecs(),
    });
    mediaRecorderRef?.current?.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef?.current?.start(); // all video will be in one chunk
  };

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef?.current?.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  useEffect(() => {
    if (!capturing && recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, {
        type: getCodecs(),
      });
      const url = URL.createObjectURL(blob);
      storeMedia(`${checksStore.activeCheckToken}.${+timeStamp}`, blob);
      onCapture(url, "video", `${checksStore.activeCheckToken}.${+timeStamp}`);
      mediaRecorderRef.current.removeEventListener(
        "dataavailable",
        handleDataAvailable
      );
    }
  }, [capturing, recordedChunks, timeStamp]);

  return {
    webcamRef,
    capturing,
    handleStopCaptureClick,
    handleStartCaptureClick,
  };
};
