import * as S from "./styled";
import { CardMedia, Portal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { forwardRef, useEffect, useRef } from "react";
import { useIndexedDb } from "src/hooks/useIndexedDb";

interface Props {
  uri: string;
  storageKey?: string;
  type: "image" | "video";
  show: boolean;
  onClose: () => void;
}

export const FullScreenMediaPlayer = ({
  uri,
  show,
  storageKey,
  type,
  onClose,
}: Props) => {
  const { readMedia } = useIndexedDb();
  const videoRef = useRef<any>();

  useEffect(() => {
    if (storageKey)
      readMedia(storageKey)
        .then((fetchedUrl: any) => {
          videoRef.current.src = fetchedUrl;
        })
        .catch((error) => {
          console.error("Error fetching video:", error);
        });
  }, [storageKey, readMedia]);

  return (
    <S.FullScreenPlayerContainer onClick={() => onClose()} $show={show}>
      <S.FullScreenCloseButton onClick={() => onClose()}>
        <CloseIcon />
      </S.FullScreenCloseButton>
      {type === "image" ? (
        <S.FullscreenImage src={uri} onClick={(e) => e.stopPropagation()} />
      ) : (
        <S.FullscreenVideo
          controls
          autoPlay
          onClick={(e) => e.stopPropagation()}
          ref={videoRef}
        ></S.FullscreenVideo>
      )}
    </S.FullScreenPlayerContainer>
  );
};


