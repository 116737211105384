import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Welcome } from "src/pages/welcome/welcome";
import { BeginReport } from "src/pages/begin-report/begin-report";
import { Signature } from "src/pages/signature/signature";
import { Success } from "src/pages/success/success";
import { Checklist } from "src/pages/checklist/checklist";

export const Root: React.FC = () => {
  function InitialRouteHandler() {
    const [initialLoad, setInitialLoad] = useState(true);
    const { checklistId } = useParams();
    const location = useLocation();
    useEffect(() => {
      setInitialLoad(false);
    }, []);
    debugger;
    const redirectPath = `/${checklistId ? `/${checklistId}` : ""}${
      location.search
    }`;

    if (initialLoad) {
      return <Navigate to={redirectPath} />;
    }

    return null;
  }
  return (
    <>
      <InitialRouteHandler />
      <Routes>
        <Route path="checklists/:checklistId" element={<Welcome />} />
        <Route path="/:checklistId?" element={<Welcome />} />
        <Route path="/begin-report" element={<BeginReport />} />
        <Route path="/checklist" element={<Checklist />} />
        <Route path="/signature" element={<Signature />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </>
  );
};
