import {
  i18n,
  useApi,
  useRetryPendingMedia,
  useStores,
} from "@checkfirst-ltd/checkfirst-customer-common";
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainLayout } from "src/components/Layouts/MainLayout";
import { Card } from "src/components/UIComponents/Card";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import { PrimaryButton } from "src/components/UIComponents/Buttons";
import { Helmet } from "react-helmet-async";

type BeginReportNavigationParams = {
  id: string;
  token: string;
};

export const BeginReport: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { useFetchChecklistInfo } = useApi();
  const {
    checklistInfoStore,
    formStore,
    snackbarStore,
    checksStore,
    rootStore,
  } = useStores();
  const [loading, setLoading] = useState(false);

  const id = searchParams.get("checklistId");
  const token = searchParams.get("token");

  const { data, isLoading, isError, isSuccess } = useFetchChecklistInfo(
    id as string,
    token as string
  );

  const handleStartPress = () => {
    navigate("/checklist");
  };

  useEffect(() => {
    if (isSuccess && data && token && rootStore.isHydrated) {
      checklistInfoStore?.setChecklistInfoState(
        data,
        id as string,
        token as string
      );
      if (!checksStore.draftedChecklists[token]) {
        const { id, title, description, unit, client } = data;
        checksStore.addChecklist(token, {
          id,
          title,
          description,
          unit,
          client,
          token,
        });
        formStore?.initFormItems(data.checklistItems);
      } else {
        setLoading(true);
        formStore?.reinitializeAndValidateFormItems(data.checklistItems);
        navigate("/checklist");
      }
    }
  }, [
    isSuccess,
    data,
    id,
    token,
    rootStore.isHydrated,
    checksStore.draftedChecklists,
  ]);

  if (isError) {
    navigate("/");
    snackbarStore.showError(t("homePage.invalidLinkError"));
  }

  const onBackClick = () => {
    navigate("/");
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Checkfirst</title>
      </Helmet>
      <Card
        loading={isLoading || loading}
        onBackClick={onBackClick}
        title={data?.title}
        subtitle={data?.description}
        onTranslatePress={(lang) => i18n.changeLanguage(lang)}
        image={
          (data?.unit.imageURL || data?.client.imageURL) && (
            <img
              src={data?.unit.imageURL ?? data?.client.imageURL}
              alt="unit or client logo"
            />
          )
        }
        body={
          <>
            <Typography variant="body2" color="secondary">
              {t("beginReportPage.unitName")}
            </Typography>
            <Typography variant="body1" color="primary">
              {data?.unit.title}
            </Typography>
            <SpacingDivider $spacing={2} />
            <Typography variant="body2" color="secondary">
              {t("beginReportPage.unitId")}
            </Typography>
            <Typography variant="body1" color="primary">
              {data?.unit.id}
            </Typography>

            <PrimaryButton
              fullWidth
              variant="contained"
              onClick={handleStartPress}
            >
              {t("beginReportPage.startButton")}
            </PrimaryButton>
            <SpacingDivider $spacing={2} />
            <Button variant="text" fullWidth onClick={onBackClick}>
              {t("beginReportPage.saveForLaterButton")}
            </Button>
          </>
        }
      />
    </MainLayout>
  );
};
