import { useEffect, useState } from "react";

export const useElementDimensions = (id: string) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const container = document.getElementById(id);
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const target: any = entry.target;
        setWidth(target?.offsetWidth ?? 0);
        setHeight(target?.offsetHeight ?? 0);
      }
    });

    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, []);
  return { width, height };
};
