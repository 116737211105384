import { styled } from "@mui/system";

export const Container = styled("div")(
  ({ theme }) => `
  display: flex;
  padding: 0 ${theme.spacing(2)};
  flex-direction: row;
  gap: ${theme.spacing(1.2)};
`
);
