import { RetryMediaUiProps } from "@checkfirst-ltd/checkfirst-customer-common";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "src/components/UIComponents/Buttons";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import { ResponsiveModal } from "src/components/UIComponents/ResponsiveModal";

export const RetryMediaModal = ({
  open,
  numberOfFailed,
  totalMediaCount,
  onClose,
  onRetryPress,
}: RetryMediaUiProps) => {
  const { t } = useTranslation();
  return (
    <ResponsiveModal
      open={open}
      onClose={onClose}
      title={t("retryModal.title")}
      closeButton={true}
      width={"50%"}
    >
      <SpacingDivider $spacing={2} />
      <Typography>
        {t("retryModal.subtitle", {
          failed: numberOfFailed,
          total: totalMediaCount,
        })}
      </Typography>
      <SpacingDivider $spacing={2} />
      <PrimaryButton
        fullWidth
        onClick={() => onRetryPress()}
        variant="contained"
      >
        {t("retryModal.retryButton")}
      </PrimaryButton>
    </ResponsiveModal>
  );
};
