import { useTranslation } from "react-i18next";
import { AnswerUiProps } from "@checkfirst-ltd/checkfirst-customer-common";
import * as S from "./styled";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import DocumentScanner from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/DocumentScanner.svg";

export const TextAnswer = ({ value, type, onChange }: AnswerUiProps) => {
  const { t } = useTranslation();
  return (
    <FormControl variant="standard" style={{ width: "100%" }}>
      <S.StyledInput
        type={type === "NUMBER" ? "number" : "text"}
        value={value}
        multiline={type === "TEXT"}
        onChange={(e) => {
          onChange?.(e.target.value);
          e.stopPropagation();
        }}
        endAdornment={
          <S.StyledInputAdornment position="end" $number={type === "NUMBER"}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                console.log("scan clicked");
              }}
            >
              <SvgIcon
                component={DocumentScanner as any}
                style={{ width: "16px", height: "16px" }}
              />
            </IconButton>
          </S.StyledInputAdornment>
        }
      />
    </FormControl>
  );
};
