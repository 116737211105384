import { useState } from "react";
import * as S from "./styled";
import { IconButton, Typography } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { InstructionsModal } from "src/components/UIComponents/InstructionsModal";
interface Props {
  text: string;
  instruction?: string;
  imageURL?: string;
  required?: boolean;
  mobileView?: boolean;
}

export const Question = ({
  text,
  instruction,
  imageURL,
  mobileView,
  required,
}: Props) => {
  const [openInstructionModal, setOpenInstructionModal] = useState(false);

  return (
    <>
      <S.Container $mobileView={mobileView}>
        <S.TextContainer>
          <Typography variant="body1">{text}</Typography>
          {required && (
            <Typography variant="body1" color="error">
              *
            </Typography>
          )}
        </S.TextContainer>
        {(instruction || imageURL) && (
          <S.StyledIconButton
            color="secondary"
            onClick={() => setOpenInstructionModal(true)}
          >
            <InfoOutlined />
          </S.StyledIconButton>
        )}
      </S.Container>
      <InstructionsModal
        open={openInstructionModal}
        instruction={instruction}
        imageURL={imageURL}
        onClose={() => setOpenInstructionModal(false)}
      />
    </>
  );
};
