import { Icon, Typography } from "@mui/material";
import { styled } from "@mui/system";
import CircleIcon from "@mui/icons-material/Circle";
export const Container = styled("div")(
  ({ theme }) => `
  position: absolute;
  left: ${theme.spacing(3)};
  top: ${theme.spacing(2)};
  display: flex;
  gap: ${theme.spacing(1)};
  flex-direction: row;
`
);

export const StyledIcon = styled(CircleIcon)(
  ({ theme }) => `
  color: ${theme.palette.recording};
`
);

export const Text = styled(Typography)(
  ({ theme }) => `
  color: white;
`
);
