import {
  Button,
  ToggleButtonGroup,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";

export const Container = styled("div")(
  ({ theme }) => `
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: black;
`
);

export const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  bottom: ${theme.spacing(5)};
  left: 50%;
  transform: translateX(-50%);
  z-index: 6;
  `
);

export const CloseButton = styled(IconButton)(
  ({ theme }) => `
  position: absolute;
  top: ${theme.spacing(2)};
  right: ${theme.spacing(3)};
  z-index: 6;
  `
);

export const StopRecordingIcon = styled(StopCircleOutlinedIcon)(
  ({ theme }) => `
  color: ${theme.palette.recording};
  width: 60px;
  height: 60px;
  `
);

export const TabsWrapper = styled("div")(
  ({ theme }) => `
  position: absolute;
  z-index: 7;
  top: ${theme.spacing(2)};
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 6px;
`
);

export const LoadingIndicator = styled(CircularProgress)(
  ({ theme }) => `
  position: absolute;
  z-index: 4;
`
);
