import { useEffect, useRef, useState } from "react";
import * as S from "./styled";
import { FullScreenMediaPlayer } from "src/components/UIComponents/FullScreenMediaPlayer";
import { fetchVideo } from "src/utils/videoUtils";
import { images } from "src/assets/images";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  url: string;
  type: "image" | "video";
  storageKey?: string;
  onDeleteMedia?: () => void;
}

export const MediaCard = ({ url, type, storageKey, onDeleteMedia }: Props) => {
  const [fullScreen, setFullScreen] = useState(false);
  const videoRef = useRef<any>();
  const [isUrlPlayable, setIsUrlPlayable] = useState(false);

  useEffect(() => {
    fetchVideo(url)
      .then(() => setIsUrlPlayable(true))
      .catch(() => setIsUrlPlayable(false));
  }, [url]);

  return (
    <>
      <S.Container>
        {type === "image" ? (
          <S.Image src={url} onClick={() => setFullScreen(true)} />
        ) : isUrlPlayable ? (
          <S.Video
            onClick={() => setFullScreen(true)}
            src={url}
            ref={videoRef}
          ></S.Video>
        ) : (
          <S.ThumbnailImage
            src={images.videoPlay}
            onClick={() => setFullScreen(true)}
          />
        )}
        {onDeleteMedia && (
          <IconButton onClick={onDeleteMedia}>
            <DeleteIcon />
          </IconButton>
        )}
      </S.Container>

      {fullScreen && (
        <FullScreenMediaPlayer
          uri={url}
          storageKey={storageKey}
          show={fullScreen}
          onClose={() => setFullScreen(false)}
          type={type}
        />
      )}
    </>
  );
};
