import { Card, IconButton } from "@mui/material";
import { styled } from "@mui/system";

export const Image = styled("img")(
  ({ theme }) => `
  width: inherit;
  max-height: 300px;
  max-width: 500px;
  `
);

export const ThumbnailImage = styled("img")(
  ({ theme }) => `
  max-width: 100%;
  `
);


export const Video = styled("video")(
  ({ theme }) => `
  width: inherit;
  max-height: 300px;
  max-width: 500px;
  `
);

export const Container = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`
);