import {
  AppUtilsInterface,
  ChecklistItemType,
  FormItem,
} from "@checkfirst-ltd/checkfirst-customer-common";
import axios from "axios";
import { Buffer } from "buffer";
import localforage from "localforage";

async function convertBlobsToSingleBuffer(blobs: Blob[]) {
  // Convert each Blob to an ArrayBuffer
  const arrayBuffers = await Promise.all(
    blobs.map((blob) => blob.arrayBuffer())
  );

  // Calculate the total length of the concatenated ArrayBuffer
  const totalLength = arrayBuffers.reduce(
    (acc, buffer) => acc + buffer.byteLength,
    0
  );

  // Create a new ArrayBuffer with the total length
  const concatenatedArrayBuffer = new Uint8Array(totalLength);

  // Copy each ArrayBuffer into the new concatenated ArrayBuffer
  let offset = 0;
  for (const arrayBuffer of arrayBuffers) {
    concatenatedArrayBuffer.set(new Uint8Array(arrayBuffer), offset);
    offset += arrayBuffer.byteLength;
  }

  // Convert the concatenated ArrayBuffer to a Buffer
  return Buffer.from(concatenatedArrayBuffer.buffer);
}

export const AppUtils: AppUtilsInterface = {
  baseUrl: process.env.REACT_APP_API_URL ?? "",
  uploadFile: async (media: string, type?: "video" | "image") => {
    if (!media) {
      throw new Error("No file to upload");
    }
    let dataToUpload;
    if (type === "video") {
      // dataToUpload = await convertBlobsToSingleBuffer(media as Blob[]);
      const file = await fetch(media);
      const arrayBuffer = await file.arrayBuffer();
      dataToUpload = Buffer.from(arrayBuffer);
    } else {
      dataToUpload = Buffer.from(media.split(";base64,")[1], "base64");
    }
    const client = axios.create();
    // Get the upload URL
    const response = await client.post(
      `${process.env.REACT_APP_API_URL}/et-file-manager/upload-url`,
      {
        domain: "REPORTS",
      }
    );
    const { uploadUrl, fileUrl } = response.data;

    // Upload the file to S3
    const uploadResponse = await client.put(uploadUrl, dataToUpload, {
      headers: {
        "Content-Type": type === "image" ? "image/jpeg" : "video/mp4",
      },
    });

    if (uploadResponse.status !== 200) {
      throw new Error("Failed to upload file");
    }
    debugger;

    return fileUrl;
  },
  readAndUploadFileFromStorage: async (
    storageUrl?: string,
    type?: "video" | "image"
  ): Promise<string> => {
    if (!storageUrl) {
      throw new Error("No file to upload");
    }
    const file: Blob | null = await localforage.getItem(storageUrl);
    if (!file) {
      throw new Error("No file to upload");
    }
    const arrayBuffer = await file.arrayBuffer();
    const dataToUpload = Buffer.from(arrayBuffer);
    const client = axios.create();
    // Get the upload URL
    const response = await client.post(
      `${process.env.REACT_APP_API_URL}/et-file-manager/upload-url`,
      {
        domain: "REPORTS",
      }
    );
    const { uploadUrl, fileUrl } = response.data;

    // Upload the file to S3
    const uploadResponse = await client.put(uploadUrl, dataToUpload, {
      headers: {
        "Content-Type": "video/mp4",
      },
    });

    if (uploadResponse.status !== 200) {
      throw new Error("Failed to upload file");
    }

    return fileUrl;
  },
};
