import { Box, IconButton, Modal } from "@mui/material";
import { styled } from "@mui/system";

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const Container = styled(Box)<{
  width?: string;
}>(
  ({ theme, width }) => `
    background-color: ${theme.palette.background.paper};
    width: 100%;
    margin-inline: ${theme.spacing(2)};
    border-radius: 6px;
    ${theme.breakpoints.up("lg")}{
      width: ${width ?? "60%"};
    }
    padding: ${theme.spacing(4)};
    overflow-y: auto;
    max-height: 80vh;
    max-width: 500px;
    box-sizing: border-box;
`
);

export const HeaderContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
);
