import { Button, Card, CardHeader } from "@mui/material";
import { styled } from "@mui/system";

export const StyledCard = styled(Card)(
  ({ theme }) => `
  border: 2px solid ${theme.palette.borders.primary};
  box-shadow: none;
  border-radius: 6px 6px 3px 3px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
);

export const StyledCardHeader = styled("div")(
  ({ theme }) => `
  border-bottom: 2px solid ${theme.palette.borders.primary};
  padding: ${theme.spacing(2)};
`
);

export const NextButton = styled(Button)(
  ({ theme }) => `
  margin-left: auto;
  display: block;
  margin-top: ${theme.spacing(2)};
  text-transform: none;
`
);
