import { styled } from "@mui/system";

export const ImageThumbnail = styled("img")(
  ({ theme }) => `
  width: 100%;
  min-height: 100%;
`
);

export const VideoThumbnail = styled("video")(
  ({ theme }) => `
  min-width: 100%;
  min-height: 100%;
`
);

export const MediaWrapper = styled("div")(
  ({ theme }) => `
  width: 150px;
  height: 150px;
  overflow: hidden;
`
);

export const Container = styled("div")(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
);
