import { ReactNode } from "react";
import * as S from "./styled";

export const MainLayout = ({
  children,
  position,
}: {
  children: ReactNode;
  position?: string;
}) => {
  return (
    <S.MainPageContainer $position={position ?? "absolute"}>
      {children}
      {/* TODO: add snackbar */}
      {/* <StatefulSnackbar /> */}
    </S.MainPageContainer>
  );
};
