import { images } from "src/assets/images";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import {
  SupportedLanguages,
  useApi,
  useCleanupStorage,
  useSharedUtils,
  useStores,
} from "@checkfirst-ltd/checkfirst-customer-common";
import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "src/components/Layouts/MainLayout";
import { Card } from "src/components/UIComponents/Card";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import { PrimaryButton } from "src/components/UIComponents/Buttons";
import { useIndexedDb } from "src/hooks/useIndexedDb";
import localforage from "localforage";
import { Helmet } from "react-helmet-async";

export const Success: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { useMutateSubmitReport } = useApi();
  const { checklistInfoStore, reportStore, snackbarStore, checksStore } =
    useStores();
  const [redirectURL,setRedirectUrl]=useState<string>('')
  const { formatDate } = useSharedUtils(process.env.API_URL as string);
  const {
    mutate: submitReport,
    isSuccess,
    data,
    isPending,
    isError,
    error,
  } = useMutateSubmitReport();
  const { cleanup } = useCleanupStorage(localforage);

  useEffect(() => {
    submitReport({
      id: checklistInfoStore?.id,
      token: checklistInfoStore?.token,
      report: reportStore?.report,
    } as any);
  }, []);

  useEffect(() => {
    if (isSuccess && checklistInfoStore?.token) {
      const checkList=checksStore.draftedChecklists[checklistInfoStore.token];
      if (checkList.unit.redirectURL)
        setRedirectUrl(checkList.unit.redirectURL)
      checksStore.changeChecklistStatus(checklistInfoStore?.token, "SUBMITTED");
      checksStore.setActiveToken(undefined);
      cleanup(checklistInfoStore.token);
    }
  }, [isSuccess, checklistInfoStore?.token]);

  const handleFinishPress = () => {
    if (redirectURL)
      window.location.href=redirectURL
    else
    navigate("/");
  };

  useEffect(() => {
    if (isError) {
      snackbarStore.showError(error?.message ?? t("successPage.error"));
      navigate("/signature");
    }
  }, [isError, error]);

  return (
    <MainLayout>
      <Helmet>
        <title>Checkfirst</title>
      </Helmet>
      <Card
        loading={isPending}
        title={t("successPage.title")}
        subtitle={t("successPage.subtitle")}
        image={<img src={images.logo} />}
        body={
          <>
            <Typography variant="body2" color="secondary">
              {t("successPage.reportIdLabel")}
            </Typography>
            <Typography variant="body1" color="primary">
              {data?.reportId}
            </Typography>
            <SpacingDivider $spacing={2} />
            <Typography variant="body2" color="secondary">
              {t("successPage.checklistTitleLabel")}
            </Typography>
            <Typography variant="body1" color="primary">
              {checklistInfoStore?.multipleChecklist?.title}
            </Typography>
            <SpacingDivider $spacing={2} />
            <Typography variant="body2" color="secondary">
              {t("successPage.inspectorNameLabel")}
            </Typography>
            <Typography variant="body1" color="primary">
              {reportStore?.report?.signatures?.[0]?.name}
            </Typography>
            <SpacingDivider $spacing={2} />
            <Typography variant="body2" color="secondary">
              {t("successPage.dateLabel")}
            </Typography>
            <Typography variant="body1" color="primary">
              {reportStore?.report?.signatures?.[0]?.date &&
                formatDate(
                  new Date(reportStore?.report?.signatures?.[0]?.date),
                  "MMMM do, yyyy",
                  i18n.language as SupportedLanguages
                )}
            </Typography>
            <SpacingDivider $spacing={2} />
            <PrimaryButton
              variant="contained"
              onClick={() => handleFinishPress()}
              fullWidth
            >
              {t("successPage.finishButtonLabel")}
            </PrimaryButton>
          </>
        }
      />
    </MainLayout>
  );
};
