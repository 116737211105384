import { SvgIcon } from "@mui/material";
import { IDetectedBarcode, Scanner } from "@yudiel/react-qr-scanner";
import { useState } from "react";
import { BottomActionSheet } from "src/components/UIComponents/BottomActionSheet";
import { PrimaryButton } from "src/components/UIComponents/Buttons";
import QrScanIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/QrCodeScannerOutlined.svg";
import { useTranslation } from "react-i18next";
import * as S from "./styled";
import { useCameraPermissions } from "src/hooks/useCameraPermissions";

interface Props {
  onScan: (scannedUrl: string) => void;
  onError?: (error: any) => void;
}
export const QrCodeScanner = ({ onScan }: Props) => {
  const { permissionAllowed, requestPermission, OpenSettingsModal } =
    useCameraPermissions();
  const [showScanner, setShowScanner] = useState(false);
  const onScanQr = (result: IDetectedBarcode[]) => {
    if (result.length <= 0) return;
    const url = result[0].rawValue;
    onScan(url);
    setShowScanner(false);
  };
  const { t } = useTranslation();

  const onScanClicked = () => {
    if (!permissionAllowed) {
      requestPermission().then((res) => {
        if (res) setShowScanner(true);
      });
    }
  };
  return (
    <>
      <OpenSettingsModal />
      <S.StyledButton
        variant="contained"
        startIcon={<SvgIcon component={QrScanIcon as any} />}
        onClick={() => {
          onScanClicked();
        }}
      >
        {t("homePage.scanQrButton")}
      </S.StyledButton>
      <BottomActionSheet
        open={showScanner}
        onClose={() => setShowScanner(false)}
      >
        <S.ScannerContainer>
          <Scanner
            onScan={onScanQr}
            styles={{
              container: { height: "90vh" },
              video: { objectFit: "fill" },
              finderBorder: 0,
            }}
          />
        </S.ScannerContainer>
      </BottomActionSheet>
    </>
  );
};
