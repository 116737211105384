import { AnswerUiProps } from "@checkfirst-ltd/checkfirst-customer-common";
import { DatePicker } from "@mui/x-date-pickers";
import * as S from "./styled";
import { format, parse } from "date-fns";

export const DateAnswer = ({ value, onChange }: AnswerUiProps) => {
  return (
    <S.StyledDatePicker
      slotProps={{
        field: {
          variant: "standard",
          fullWidth: true,
          format: "dd/MM/yyyy",
        } as any,
      }}
      value={value ? parse(value, "dd/MM/yyyy", new Date()) : null}
      onChange={(date) => {
        if (date && !isNaN(date as any)) {
          const formattedDate = format(date, "dd/LL/yyyy");
          onChange?.(formattedDate);
        }else if(date === null){
          onChange?.("");
        }
      }}
    />
  );
};
