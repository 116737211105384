import { styled } from "@mui/system";

export const MainPageContainer = styled("div")<{ $position?: string }>(
  ({ theme, $position }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.surface.main};
  ${$position ? `position: ${$position};` : ""}
`
);
