import { IconButton } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled("div")<{ $mobileView?: boolean }>(
  ({ theme, $mobileView }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${theme.breakpoints.down("sm")}  {
    margin-bottom: ${theme.spacing(1)};
  }
  margin-bottom: ${$mobileView ? theme.spacing(1) : 0};
`
);
export const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
    padding: 0;
    margin-left: ${theme.spacing(0.5)};
    margin-bottom: ${theme.spacing(0.5)};
`
);

export const TextContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${theme.spacing(0.5)};
`
);
