import { ChecklistItemUiComponentProps } from "@checkfirst-ltd/checkfirst-customer-common";
import { Question } from "src/components/UIComponents/Question";
import * as S from "./styled";
import { Divider, Typography } from "@mui/material";
import { Observation } from "src/components/UIComponents/Observation";

export const ChecklistItem = ({
  title,
  instruction,
  imageURL,
  error,
  mobileView,
  mandatory,
  observation,
  children,
}: ChecklistItemUiComponentProps) => {
  return (
    <S.Container $hasError={!!error}>
      <S.ContentRow $mobileView={mobileView}>
        <S.TabletFlexItem $mobileView={mobileView}>
          <Question
            text={title}
            required={mandatory}
            instruction={instruction}
            imageURL={imageURL}
            mobileView={mobileView}
          />
        </S.TabletFlexItem>
        <S.StyledDivider />
        <S.TabletFlexItem $mobileView={mobileView}>{children}</S.TabletFlexItem>
      </S.ContentRow>
      {error && (
        <S.ErrorItem>
          <S.StyledIcon />
          <Typography variant="body1">{error}</Typography>
        </S.ErrorItem>
      )}
      {observation && <Observation {...observation} />}
    </S.Container>
  );
};
