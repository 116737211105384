import {
  CheckInfo,
  CheckStatus,
  useSharedUtils,
  useStores,
} from "@checkfirst-ltd/checkfirst-customer-common";
import { observer } from "mobx-react-lite";
import * as S from "./styled";
import { CardHeader, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import { CheckItem } from "src/components/Checks/components/CheckItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Checks = observer(() => {
  const { checksStore, rootStore } = useStores();
  const { t, i18n } = useTranslation();
  const { formatDate } = useSharedUtils(process.env.API_URL as string);
  const navigate = useNavigate();

  const onStartClick = (c: CheckInfo) => {
    checksStore.setActiveToken(c.token);
    checksStore.setDraftTime(c.token, new Date());
    rootStore.reinitializeReportStores();
    navigate(`begin-report?checklistId=${c.id}&token=${c.token}`);
  };

  const getSubtitle = (status: CheckStatus, time: Date) => {
    switch (status) {
      case "SUBMITTED":
        return (
          t("homePage.checks.submittedSubtitle") +
          formatDate(time, "h:mm a MMM d", i18n.language as any)
        );
      case "DRAFTED":
        return (
          t("homePage.checks.draftSubtitle") +
          formatDate(time, "h:mm a MMM d", i18n.language as any)
        );
      case "STARTED":
        return (
          t("homePage.checks.startedSubtitle") +
          formatDate(time, "h:mm a MMM d", i18n.language as any)
        );
    }
  };

  return (
    <S.Container>
      <S.CardHeader>
        <Typography variant="h5" color="primary">
          {t("homePage.checks.title")}
        </Typography>
      </S.CardHeader>
      <S.CardBody>
        {checksStore.checklists.map((c) => (
          <>
            <CheckItem
              {...c}
              subtitle={getSubtitle(c.status, c.lastDraftTime)}
              startButtonLabel={
                c.status === "STARTED"
                  ? t("homePage.checks.startButtonLabel")
                  : t("homePage.checks.resumeButtonLabel")
              }
              onStartClick={
                c.status !== "SUBMITTED" ? () => onStartClick(c) : undefined
              }
              imageUrl={c.unit?.imageURL ?? c.client?.imageURL}
            />
          </>
        ))}
      </S.CardBody>
    </S.Container>
  );
});
