import { Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const Container = styled("div")<{ $hasError: boolean }>(
  ({ theme, $hasError }) => `
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.palette.borders.primary};
  padding: ${theme.spacing(2)};
  ${$hasError && `border: 2px solid ${theme.palette.error.main};`}
`
);

export const ContentRow = styled("div")<{ $mobileView?: boolean }>(
  ({ theme, $mobileView }) => `
  display: flex;
  flex-direction: ${$mobileView ? "column" : "row"};
  ${theme.breakpoints.down("sm")}  {
    flex-direction: column;
  }
  width: 100%;
`
);

export const ErrorItem = styled("div")(
  ({ theme }) => `
  color: ${theme.palette.error.main};
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: ${theme.spacing(2)};
  gap: ${theme.spacing(1.2)};
`
);

export const StyledIcon = styled(InfoOutlinedIcon)(
  ({ theme }) => `
  color: ${theme.palette.error.main};
`
);

export const StyledDivider = styled("div")(
  ({ theme }) => `
  background-color: ${theme.palette.borders.primary};
  width: 1px;
  margin: 0 ${theme.spacing(2)};
`
);

export const TabletFlexItem = styled("div")<{ $mobileView?: boolean }>(
  ({ theme, $mobileView }) => `
  flex: 1;
  align-self: stretch;
  width: ${$mobileView ? "100%" : "50%"};
  ${theme.breakpoints.down("sm")}  {
    width: 100%;
  }
`
);
