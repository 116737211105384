import {
  useLocalization,
  useStores,
  useSharedUtils,
  StatefulCategory,
  useRetryPendingMedia,
} from "@checkfirst-ltd/checkfirst-customer-common";
import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { ChecklistLayout } from "src/components/Layouts/ChecklistLayout";
import { LoadingComponent } from "src/components/LoadingComponent";
import { Answer } from "src/components/UIComponents/Answer";
import { ChecklistItem } from "src/components/UIComponents/Checklistitem";
import { ObservationModal } from "src/components/UIComponents/ObservationModal";
import { SectionCard } from "src/components/UIComponents/SectionCard";
import { TextAnswer } from "src/components/UIComponents/TextAnswer";

export const Checklist: React.FC = observer(() => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  useRetryPendingMedia();
  const { checklistInfoStore, sideNavigationStore, rootStore, checksStore } =
    useStores();

  useEffect(() => {
    if ((checklistInfoStore?.checklistCategories.length ?? 0) > 0) {
      sideNavigationStore?.selectFirstCategory();
    }
  }, [rootStore.isHydrated]);

  useEffect(() => {
    if (checksStore.activeCheckToken) {
      checksStore.setDraftTime(checksStore.activeCheckToken, new Date());
      checksStore.changeChecklistStatus(
        checksStore?.activeCheckToken,
        "DRAFTED"
      );
    }
  }, [checksStore.activeCheckToken]);

  const handleSubmitPress = () => {
    navigate("/signature");
  };

  const renderCategory = useCallback(
    (props: any) => <SectionCard {...props} />,
    []
  );
  const renderChecklistItem = useCallback(
    (props: any) => <ChecklistItem {...props} />,
    []
  );

  const renderRaiseIssueModal = useCallback(
    (props: any) => <ObservationModal {...props} />,
    []
  );

  // if (!rootStore.isHydrated) {
  //   return <LoadingComponent />;
  // }

  const renderAnswer = useCallback((props: any) => <Answer {...props} />, []);
  return (
    <ChecklistLayout onSubmit={handleSubmitPress}>
      <Helmet>
        <title>Checkfirst</title>
      </Helmet>
      {(checklistInfoStore?.checklistCategories.length ?? 0) > 0 &&
        sideNavigationStore?.selectedCategory && (
          <StatefulCategory
            categoryId={sideNavigationStore.selectedCategory.id}
            categoryName={sideNavigationStore.selectedCategory.name}
            renderCategory={renderCategory}
            renderChecklistItem={renderChecklistItem}
            renderAnswer={renderAnswer}
            renderObservationModal={renderRaiseIssueModal}
            onSubmit={handleSubmitPress}
          />
        )}
    </ChecklistLayout>
  );
});
