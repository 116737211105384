import { AnswerUiProps } from "@checkfirst-ltd/checkfirst-customer-common";
import * as S from "./styled";

export const MultipleChoiceAnswer = ({
  options,
  selectedOptions,
  onOptionsSelectionChange,
}: AnswerUiProps) => {
  return (
    <S.Container>
      {options?.map((o) => (
        <S.StyledChipButton
          label={o.title}
          key={o.id}
          variant={
            selectedOptions?.find((i) => i.id === o.id) ? "filled" : "outlined"
          }
          onClick={(ev) =>
            onOptionsSelectionChange?.(
              o.id,
              o.title,
              !selectedOptions?.find((i) => i.id === o.id)
            )
          }
        />
      ))}
    </S.Container>
  );
};
