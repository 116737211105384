import { createTheme } from "@mui/material";
import common from "@checkfirst-ltd/checkfirst-customer-common";
import "@mui/material";
import * as Mui from "@mui/material";

const { theme: commonTheme } = common;
declare module "@mui/material" {
  interface Palette {
    tertiary?: {
      main: React.CSSProperties["color"];
      contrastText: React.CSSProperties["color"];
    };
    subtitleColor?: {
      main: React.CSSProperties["color"];
    };
    selectedSurface?: {
      main: React.CSSProperties["color"];
    };
    secondarySurface?: {
      main: React.CSSProperties["color"];
    };
    surface?: {
      main: React.CSSProperties["color"];
    };
    severity?: {
      HIGH: React.CSSProperties["color"];
      MEDIUM: React.CSSProperties["color"];
      LOW: React.CSSProperties["color"];
    };
    recording: React.CSSProperties["color"];
  }

  interface PaletteOptions {
    tertiary?: {
      main: string;
      contrastText: string;
    };
    subtitleColor?: {
      main: React.CSSProperties["color"];
    };
    selectedSurface?: {
      main: React.CSSProperties["color"];
    };
    secondarySurface?: {
      main: React.CSSProperties["color"];
    };
    surface?: {
      main: React.CSSProperties["color"];
    };
    severity?: {
      HIGH: React.CSSProperties["color"];
      MEDIUM: React.CSSProperties["color"];
      LOW: React.CSSProperties["color"];
    };
    chips: {
      backgroundColor: React.CSSProperties["color"];
      selected: {
        backgroundColor: React.CSSProperties["color"];
        color: React.CSSProperties["color"];
      };
    };
    borders: {
      primary: React.CSSProperties["color"];
      secondary: React.CSSProperties["color"];
    };
    recording: React.CSSProperties["color"];
  }
}

export const theme = createTheme({
  palette: {
    tonalOffset: 0.3,
    primary: {
      main: commonTheme.colors.primary,
      contrastText: commonTheme.colors.surface,
      dark: commonTheme.colors.primary,
    },
    secondary: {
      main: commonTheme.colors.subtitleColor,
      contrastText: commonTheme.colors.text,
      dark: commonTheme.colors.subtitleColor,
    },
    error: {
      main: commonTheme.colors.error,
      contrastText: commonTheme.colors.surface,
    },
    success: {
      main: commonTheme.colors.success,
      contrastText: commonTheme.colors.surface,
    },
    text: {
      primary: commonTheme.colors.text,
      secondary: commonTheme.colors.subtitleColor,
    },
    //Extra colors
    tertiary: {
      main: commonTheme.colors.tertiary,
      contrastText: commonTheme.colors.surface,
    },
    subtitleColor: {
      main: commonTheme.colors.subtitleColor,
    },
    selectedSurface: {
      main: commonTheme.colors.selectedSurface,
    },
    secondarySurface: {
      main: commonTheme.colors.secondarySurface,
    },
    surface: {
      main: commonTheme.colors.surface,
    },
    severity: commonTheme.colors.severity,
    chips: commonTheme.chips,
    borders: commonTheme.borders,
    recording: "#e4252e",
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    caption: {
      color: commonTheme.colors.subtitleColor,
    },
  },
});
