import React, { useState } from "react";
import { AnswerUiProps } from "@checkfirst-ltd/checkfirst-customer-common";
import { Box, Checkbox } from "@mui/material";
import * as S from "./styled";

export const DisclaimerAnswer = ({
  options,
  selectedOptions,
  onOptionsSelectionChange,
}: AnswerUiProps) => {
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <S.Container>
      {options?.map((option) => (
        <>
          <Checkbox
            key={option.id}
            checked={checked}
            onChange={(event) => {
              onOptionsSelectionChange?.(
                option.id,
                option.title,
                !selectedOptions?.find((i) => i.id === option.id)
              );
              handleChange(event);
            }}
          />
        </>
      ))}
    </S.Container>
  );
};
