import { OutlinedInput, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/system";
import { PrimaryButton } from "src/components/UIComponents/Buttons";

export const TextContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(0.5)};
`
);

export const ColoredLink = styled(Typography)<TypographyProps>(
  ({ theme }) => `
  a{
    color: ${theme.palette.tertiary.main};
  }
`
);

export const BodyContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  flex-wrap: wrap;
  ${theme.breakpoints.up("sm")} {
    flex-wrap: nowrap;
    flex-direction: row;
  }
`
);

export const TextBoxContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
`
);

export const StyledInput = styled(OutlinedInput)(
  ({ theme }) => `
  max-height: 50px;
`
);

export const OpenButton = styled(PrimaryButton)(
  ({ theme }) => `
  margin-top: 0;
  margin-left: ${theme.spacing(0.5)};
  &:disabled {
    background-color: transparent;
  }
  height: 50px;
`
);
