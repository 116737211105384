import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${theme.spacing(1)};
`
);

export const RoundButton = styled(Button)(
  ({ theme }) => `
  border-radius: 50%;
  border: 1px solid ${theme.palette.borders.primary};
  width: 40px;
  height: 40px;
  min-width: 40px;
`
);

export const PlusIcon = styled(AddOutlinedIcon)(
  ({ theme }) => `
  color: ${theme.palette.tertiary.main};
`
);
