import {
  FormItemMedia,
  ReportedMedia,
} from "@checkfirst-ltd/checkfirst-customer-common";
import * as S from "./styled";
import { MediaThumbnail } from "src/components/UIComponents/MediaThumbnail";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import { Divider, Icon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  description: string;
  severity?: "HIGH" | "MEDIUM" | "LOW";
  media: FormItemMedia[];
}

export const Observation = ({ description, severity, media }: Props) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <SpacingDivider $spacing={2} />
      <S.MediaContainer>
        {media.map((m) => (
          <MediaThumbnail
            url={m.localUri ?? ""}
            type={m.type as any}
            storageKey={m.storageKey}
          />
        ))}
      </S.MediaContainer>
      <SpacingDivider $spacing={1} />
      <S.TextContainer>
        <Typography fontWeight={600} variant="body2">
          {t("categoryPage.criteria.remark")}:
        </Typography>
        <Typography variant="body2"> {description}</Typography>
      </S.TextContainer>

      {severity && (
        <>
          <SpacingDivider $spacing={1} />
          <Divider />
          <SpacingDivider $spacing={1} />
          <S.TextContainer>
            <S.SeverityIcon $severity={severity} />
            <S.SeverityText $severity={severity} variant="body2">
              {t(`categoryPage.criteria.severity.${severity}`)}
            </S.SeverityText>
          </S.TextContainer>
        </>
      )}
    </S.Container>
  );
};
