import { Drawer } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled(Drawer)(
  ({ theme }) => `
  border-radius: 10px 10px 0 0;
  width: 80%;
  max-height: 80%;
`
);
