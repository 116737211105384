import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveModal } from "src/components/UIComponents/ResponsiveModal";

export const useCameraPermissions = () => {
  const [permissionAllowed, setPermissionAllowed] = useState(false);
  const [permissionModalOpen, setPermissionModalOpen] = useState(false);

  const requestPermission = async (
    video: boolean = true,
    audio: boolean = false
  ) => {
    setPermissionAllowed(false);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio,
        video,
      });
      stream.getTracks().forEach((t) => t.stop());
      setPermissionAllowed(true);
      return true;
    } catch (error) {
      setPermissionAllowed(false);
      setPermissionModalOpen(true);
    }
    return false;
  };

  return {
    requestPermission,
    permissionAllowed,
    OpenSettingsModal: () => {
      const { t } = useTranslation();
      return (
        <ResponsiveModal
          open={permissionModalOpen}
          onClose={() => {
            setPermissionModalOpen(false);
          }}
        >
          <Typography>{t("permissionModal.message")}</Typography>
        </ResponsiveModal>
      );
    },
  };
};
