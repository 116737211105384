import { CircularProgress, Grid, Typography } from "@mui/material";
import { images } from "src/assets/images";

export const LoadingComponent: React.FC = () => {
  return (
    <Grid
      container
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
      height={"500px"}
    >
      <Grid item>
        <img src={images.logo} style={{ width: "100px", height: "100px" }} />
      </Grid>
      <Grid item>
        <CircularProgress color="inherit" size={32} />
      </Grid>
    </Grid>
  );
};
