import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { SideNavigation } from "src/components/UIComponents/SideNavigation";
import {
  StatefulSideNavigation,
  useStores,
} from "@checkfirst-ltd/checkfirst-customer-common";
import { observer } from "mobx-react-lite";
import * as S from "./styled";
import { IconButton, SvgIcon, useMediaQuery } from "@mui/material";
import SideNavIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/SideNavIcon.svg";
import { useNavigate } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
  onSubmit: () => void;
}

export const ChecklistLayout: React.FC<Props> = observer((props) => {
  const { children, onSubmit } = props;
  const { sideNavigationStore } = useStores();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <StatefulSideNavigation
        onSubmit={onSubmit}
        renderUiComponent={(props: any) => <SideNavigation {...props} />}
        onBackClick={() => {
          navigate("/");
        }}
      />
      <S.Main open={sideNavigationStore?.open} $isMobile={isMobile}>
        <S.StyledIcon onClick={() => sideNavigationStore?.toggleOpen()}>
          <SvgIcon component={SideNavIcon as any} />
        </S.StyledIcon>
        {children}
      </S.Main>
    </Box>
  );
});
