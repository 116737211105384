import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  AnswerUiProps,
  ChecklistItemType,
} from "@checkfirst-ltd/checkfirst-customer-common";
import { MultipleChoiceAnswer } from "src/components/UIComponents/MultipleChoiceAnswer";
import { TextAnswer } from "src/components/UIComponents/TextAnswer";
import { ImageAnswer } from "src/components/UIComponents/ImageAnswer";
import * as S from "./styled";
import { DisclaimerAnswer } from "src/components/UIComponents/DisclaimerAnswer";
import { DateAnswer } from "src/components/UIComponents/DateAnswer";
import { TimeAnswer } from "src/components/UIComponents/TimeAnswer";
import { SignatureAnswer } from "src/components/UIComponents/SignatureAnswer";

export const Answer = ({
  type,
  onAddButtonClicked,
  ...props
}: AnswerUiProps) => {
  const getAnswerComponent = useCallback(
    (props: AnswerUiProps) => {
      switch (type) {
        case "Yes/No":
        case "CHECK_BOX":
        case "RADIO_BUTTON":
        case "MULTIPLE_CHOICE":
        case "CONDITION":
          return <MultipleChoiceAnswer {...props} />;
        case "NUMBER":
        case "TEXT":
          return <TextAnswer {...props} />;
        case "MULTI_MEDIA":
        case "MEDIA":
          return <ImageAnswer {...props} />;
        case "DISCLAIMER":
          return <DisclaimerAnswer {...props} />;
        case "DATE":
          return <DateAnswer {...props} />;
        case "TIME":
          return <TimeAnswer {...props} />;
        case "SIGNATURE":
          return <SignatureAnswer {...props} />;
      }
    },
    [type]
  );

  return (
    <S.Container>
      {getAnswerComponent({ ...props, type })}
      <S.RoundButton onClick={() => onAddButtonClicked?.()}>
        <S.PlusIcon fontSize="medium" />
      </S.RoundButton>
    </S.Container>
  );
};
