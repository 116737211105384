import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
const drawerWidth = 340;

export const LayoutContainer = styled("div")(
  ({ theme }) => `
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.secondarySurface.main};
  `
);

export const Main = styled("main")<{
  open?: boolean;
  $isMobile: boolean;
  theme?: any;
}>(({ theme, open, $isMobile }) => ({
  flexGrow: 1,
  // display: "flex",
  // flexDirection: "column",
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: $isMobile ? 0 : `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const StyledIcon = styled(IconButton)(
  ({ theme }) => `
  svg{
    fill: transparent;
  }
  margin-left: -${theme.spacing(1)};
`
);
