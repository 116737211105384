import { SupportedLanguages } from "@checkfirst-ltd/checkfirst-customer-common";
import { ReactNode } from "react";
import * as S from "./styled";
import {
  CircularProgress,
  Divider,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import { LoadingComponent } from "src/components/LoadingComponent";
import { TranslationSheet } from "src/components/UIComponents/TranslationSheet";
interface Props {
  loading?: boolean;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  image?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  variant?: "primary" | "secondary" | "tertiary";
  onTranslatePress?: (key: SupportedLanguages) => void;
  onBackClick?: () => void;
}

export const Card = ({
  loading,
  title,
  subtitle,
  image,
  body,
  footer,
  variant,
  onBackClick,
  onTranslatePress,
}: Props) => {
  return (
    <S.CardContainer $variant={variant ?? "primary"}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <S.ButtonsContainer>
            <div>
              {onBackClick && (
                <>
                  <S.BackButton onClick={onBackClick} color="primary">
                    <ArrowBackIcon />
                  </S.BackButton>
                  <SpacingDivider $spacing={2} />
                </>
              )}
            </div>
            {onTranslatePress && (
              <div>
                <TranslationSheet onTranslatePress={onTranslatePress} />
                <SpacingDivider $spacing={2} />
              </div>
            )}
          </S.ButtonsContainer>
          {image && (
            <>
              <S.ImageContainer>{image}</S.ImageContainer>
              <SpacingDivider $spacing={2} />
            </>
          )}
          {title && (
            <>
              <Typography variant="h4" color="primary">
                {title}
              </Typography>
            </>
          )}
          {subtitle && (
            <>
              <Typography variant="body1" color="secondary">
                {subtitle}
              </Typography>
            </>
          )}
          {body && (
            <>
              <SpacingDivider $spacing={2} />
              {body}
            </>
          )}
          {footer && (
            <>
              <SpacingDivider $spacing={2} />
              <Divider />
              <SpacingDivider $spacing={1} />
              {footer}
            </>
          )}
        </>
      )}
    </S.CardContainer>
  );
};
