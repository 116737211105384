import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { PrimaryButton } from "src/components/UIComponents/Buttons";

export const ButtonsWrapper = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(1)};
`
);

export const StyledButton = styled(PrimaryButton)(
  ({ theme }) => `
  border-radius: 6px;
  margin-top: ${theme.spacing(1)};
`
);
