import { AnswerUiProps } from "@checkfirst-ltd/checkfirst-customer-common";
import * as S from "./styled";
import { format } from "date-fns";

export const TimeAnswer = ({ value, onChange }: AnswerUiProps) => {
  return (
    <S.StyledTimePicker
      slotProps={{
        field: {
          variant: "standard",
          fullWidth: true,
        } as any,
      }}
      onChange={(value) => {
        if (value && !isNaN(value as any)) {
          const formattedTime = format(value, "hh:mm aa");
          onChange?.(formattedTime);
        }
      }}
      ampm={true}
    />
  );
};
