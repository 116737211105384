import { useState } from "react";
import FileUploadIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/FileUploadFilled.svg";
import { CategoryUiComponentProps } from "@checkfirst-ltd/checkfirst-customer-common";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardHeader,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as S from "./styled";
import { PrimaryButton } from "src/components/UIComponents/Buttons";
import { Virtuoso } from "react-virtuoso";

export const SectionCard = ({
  title,
  data,
  onNextClick,
  onSubmit,
  renderItem,
  keyExtractor,
}: CategoryUiComponentProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <>
      <S.StyledCard>
        <S.StyledCardHeader>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
        </S.StyledCardHeader>
        <Virtuoso
          useWindowScroll
          totalCount={data.length}
          data={data}
          itemContent={(index, data) => renderItem({ item: data, index })}
        />
      </S.StyledCard>
      {onNextClick && (
        <S.NextButton variant="outlined" onClick={() => onNextClick?.()}>
          {isMobile
            ? t("categoryPage.nextButton")
            : t("categoryPage.nextSectionButton")}
        </S.NextButton>
      )}
      {isMobile && (
        <PrimaryButton
          variant="contained"
          fullWidth
          startIcon={<SvgIcon component={FileUploadIcon as any} />}
          onClick={() => onSubmit?.()}
        >
          {t("sideNavigation.submitCheckButton")}
        </PrimaryButton>
      )}
    </>
  );
};
