import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  SvgIcon,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CheckStatus } from "@checkfirst-ltd/checkfirst-customer-common";
import { SpacingDivider } from "src/components/UIComponents/Divider";
import { useTranslation } from "react-i18next";
import { Card } from "src/components/UIComponents/Card";
import { PrimaryButton } from "src/components/UIComponents/Buttons";
import * as S from "./styled";
import CheckDrafted from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/CheckDrafted.svg";
import CheckStarted from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/CheckStarted.svg";
import CheckSubmitted from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/CheckSubmitted.svg";

interface Props {
  id: string | number;
  title: string;
  status: CheckStatus;
  subtitle: string;
  description: string;
  imageUrl?: string;
  unit: {
    id: number | string;
    title: string;
  };
  startButtonLabel: string;
  onStartClick?: () => void;
}

export const CheckItem = ({
  title,
  subtitle,
  description,
  unit,
  status,
  startButtonLabel,
  imageUrl,
  onStartClick,
}: Props) => {
  const { t } = useTranslation();

  const getIcon = () => {
    switch (status) {
      case "DRAFTED":
        return CheckDrafted;
      case "SUBMITTED":
        return CheckSubmitted;
      case "STARTED":
        return CheckStarted;
    }
  };
  return (
    <Accordion elevation={0}>
      <S.StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <SvgIcon component={getIcon() as any} />

        <S.TextContainer>
          <Typography color="primary" variant="body1">
            {title}
          </Typography>
          <Typography color="secondary" variant="body2">
            {subtitle}
          </Typography>
        </S.TextContainer>
      </S.StyledAccordionSummary>
      <S.StyledAccordionDetails>
        <Card
          variant="tertiary"
          title={title}
          subtitle={description}
          image={imageUrl && <img src={imageUrl} alt="unit or client logo" />}
          body={
            <>
              <Typography variant="body2" color="secondary">
                {t("beginReportPage.unitName")}
              </Typography>
              <Typography variant="body1" color="primary">
                {unit.title}
              </Typography>
              <SpacingDivider $spacing={2} />
              <Typography variant="body2" color="secondary">
                {t("beginReportPage.unitId")}
              </Typography>
              <Typography variant="body1" color="primary">
                {unit.id}
              </Typography>
              {onStartClick && (
                <PrimaryButton
                  fullWidth
                  variant="contained"
                  onClick={onStartClick}
                >
                  {startButtonLabel}
                </PrimaryButton>
              )}
            </>
          }
        />
      </S.StyledAccordionDetails>
    </Accordion>
  );
};
