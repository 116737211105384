import { useEffect, useRef, useState } from "react";
import CameraIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/Camera.svg";
import { AnswerUiProps } from "@checkfirst-ltd/checkfirst-customer-common";
import { useTranslation } from "react-i18next";
import * as S from "./styled";
import { SecondaryButton } from "src/components/UIComponents/Buttons";
import { SvgIcon } from "@mui/material";
import { MediaCard } from "src/components/UIComponents/MediaCard";
import { MediaCapture } from "src/components/UIComponents/MediaCapture";
import { useGeoLocation } from "src/hooks/useGeoLocation";

export const ImageAnswer = ({ media, onChange, onDeleteMedia }: AnswerUiProps) => {
  const { t } = useTranslation();
  const [openMediaPicker, setOpenMediaPicker] = useState(false);

  const location = useGeoLocation();

  const onMediaChange = (
    media: string,
    type: "image" | "video",
    storageKey?: string
  ) => {
    setOpenMediaPicker(false);
    if (type === "video") {
      onChange?.(
        media,
        type,
        undefined,
        {
          latitude: (location?.latitude as number) ?? 0,
          longitude: (location?.longitude as number) ?? 0,
        },
        storageKey
      );
    } else {
      onChange?.(
        media as string,
        type,
        undefined,
        {
          latitude: (location?.latitude as number) ?? 0,
          longitude: (location?.longitude as number) ?? 0,
        },
        storageKey
      );
    }
  };

  return (
    <S.Container>
      <SecondaryButton
        variant="outlined"
        startIcon={
          <SvgIcon
            component={CameraIcon as any}
            style={{ width: "16px", height: "16px" }}
          />
        }
        fullWidth={false}
        onClick={() => setOpenMediaPicker(true)}
      >
        {t("categoryPage.criteria.addImageButton")}
      </SecondaryButton>
      <MediaCapture
        open={openMediaPicker}
        onClose={() => setOpenMediaPicker(false)}
        onCapture={onMediaChange}
      />
      {media &&
        media.map((m) => (
          <>
            <S.Divider />
            <MediaCard
              url={m.localUrl ?? ""}
              type={m.mediaType}
              storageKey={m.storageKey}
              onDeleteMedia={() => {
                onDeleteMedia?.(m.localUrl ?? "");
              }}
            />
          </>
        ))}
    </S.Container>
  );
};
