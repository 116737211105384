import { useEffect, useState } from "react";

export const useGeoLocation = () => {
  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
  }>();
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation(position.coords);
        },
        (error) => {
          console.error("geo location permission is denied");
        }
      );
    } else {
      console.error("geo location is not supported by the browser");
    }
  }, []);
  return location;
};
