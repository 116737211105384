import { Badge, Drawer, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "inherit",
    boxSizing: "border-box",
    zIndex: 3,
  },
}));

export const StyledDrawerHeader = styled("div")(
  ({}) => `
  display: flex;
  justify-content: space-between;
`
);

export const TitleContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  padding: ${theme.spacing(2)} 0;
`
);

export const StyledIcon = styled(IconButton)(
  ({ theme }) => `
  svg{
    fill: transparent;
  }
`
);

export const Subtitle = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.subtitleColor.main};
`
);

export const ItemContainer = styled("div")<{ $selected: boolean }>(
  ({ theme, $selected }) => `
  display: flex;
  gap: ${theme.spacing(2)};
  align-items: center;
  padding: ${theme.spacing(2)};
  background-color: ${
    $selected ? theme.palette.selectedSurface.main : "transparent"
  };
`
);

export const ItemTitleContainer = styled("div")(
  ({ theme }) => `
`
);

export const ItemIcon = styled("div")(
  ({ theme }) => `
`
);

export const ButtonWrapper = styled("div")(
  ({ theme }) => `
  margin: 0 ${theme.spacing(2)};
`
);

export const StyledBadge = styled("div")(
  ({ theme }) => `
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
);

export const ErrorBadge = styled(StyledBadge)(
  ({ theme }) => `
  background-color: ${theme.palette.error.main};
  color: ${theme.palette.error.contrastText};
`
);

export const SuccessBadge = styled(StyledBadge)(
  ({ theme }) => `
  background-color: ${theme.palette.success.main};
  color: ${theme.palette.success.contrastText};
`
);

export const IndexBadge = styled(StyledBadge)(
  ({ theme }) => `
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.contrastText};
  font-size: ${(theme.typography as any).body2.fontSize};
`
);
