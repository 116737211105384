import { styled } from "@mui/system";
import { IconButton, MenuItem as MuiMenuItem } from "@mui/material";

export const MenuItem = styled(MuiMenuItem)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};
  display: flex;
  justify-content: center;  
`
);

export const StyledButton = styled(IconButton)(
  ({ theme }) => `
  padding: 0;
`
);