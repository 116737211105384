import { useEffect, useState } from "react";
import * as S from "./styled";
import { FullScreenMediaPlayer } from "src/components/UIComponents/FullScreenMediaPlayer";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { images } from "src/assets/images";
import { fetchVideo } from "src/utils/videoUtils";

interface Props {
  url: string;
  type: "image" | "video";
  storageKey?: string;
  onDeleteClick?: () => void;
}

export const MediaThumbnail = ({
  url,
  type,
  storageKey,
  onDeleteClick,
}: Props) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [isUrlPlayable, setIsUrlPlayable] = useState(false);

  useEffect(() => {
    fetchVideo(url)
      .then(() => setIsUrlPlayable(true))
      .catch(() => setIsUrlPlayable(false));
  }, [url]);

  return (
    <>
      <S.Container>
        <S.MediaWrapper>
          {type === "image" ? (
            <S.ImageThumbnail src={url} onClick={() => setFullScreen(true)} />
          ) : isUrlPlayable ? (
            <S.VideoThumbnail
              onClick={() => setFullScreen(true)}
              src={url}
            ></S.VideoThumbnail>
          ) : (
            <S.ImageThumbnail
              src={images.videoPlay}
              onClick={() => setFullScreen(true)}
            />
          )}
        </S.MediaWrapper>
        {onDeleteClick && (
          <IconButton onClick={onDeleteClick}>
            <DeleteIcon />
          </IconButton>
        )}
      </S.Container>
      {fullScreen && (
        <FullScreenMediaPlayer
          uri={url}
          storageKey={storageKey}
          type={type}
          show={fullScreen}
          onClose={() => setFullScreen(false)}
        />
      )}
    </>
  );
};
