import { ModalProps, IconButton, Typography } from "@mui/material";
import * as S from "./styled";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface Props extends Omit<ModalProps, "children"> {
  width?: string;
  closeButton?: boolean;
  children?: React.ReactNode;
}

export const ResponsiveModal = ({
  width,
  title,
  closeButton,
  children,
  ...props
}: Props) => {
  return (
    <S.StyledModal {...(props as any)} style={{ zIndex: 5 }}>
      <>
        <S.Container width={width}>
          <S.HeaderContainer>
            <Typography variant="h4" color="primary.main">
              {title}
            </Typography>
            {closeButton && (
              <IconButton
                onClick={(e) => props.onClose?.(e, "closeButtonClick" as any)}
                color="primary"
              >
                <CloseIcon />
              </IconButton>
            )}
          </S.HeaderContainer>
          {children}
        </S.Container>
      </>
    </S.StyledModal>
  );
};
