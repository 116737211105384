import { IconButton } from "@mui/material";
import { styled } from "@mui/system";

export const FullscreenVideo = styled("video")(
  ({ theme }) => `
  height: 100%;
  max-width: 100%;
  `
);

export const FullscreenImage = styled("img")(
  ({ theme }) => `
  height: 100%;
  `
);

export const FullScreenPlayerContainer = styled("div")<{ $show: boolean }>(
  ({ theme, $show }) => `
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.71);
  z-index: 6;
  display: ${$show ? "flex" : "none"};
  justify-content: center;
  `
);

export const FullScreenCloseButton = styled(IconButton)(
  ({ theme }) => `
  align-self: flex-end;
  position: absolute;
  top: 0; 
  right: ${theme.spacing(4)};
  color: ${theme.palette.primary.contrastText};
  z-index: 6;
  `
);
