import { CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

export const StyledProgress = styled(CircularProgress)(
  ({ theme }) => `
  width: 14px !important;
  height: 14px !important;
`
);

export const ErrorContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(0.5)};
  align-items: center;
`
);
