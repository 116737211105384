import Webcam from "react-webcam";
import * as S from "./styled";
import { Portal, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideocamIcon from "@mui/icons-material/Videocam";
import CloseIcon from "@mui/icons-material/Close";
import { Timer } from "src/components/UIComponents/Timer";
import { useRecordVideo } from "src/hooks/useRecordVideo";
import { useCameraPermissions } from "src/hooks/useCameraPermissions";

interface Props {
  open: boolean;
  onClose: () => void;
  onCapture: (
    path: string,
    type: "image" | "video",
    storageKey?: string
  ) => void;
}

export const MediaCapture = ({ open, onClose, onCapture }: Props) => {
  const { innerWidth: width, innerHeight: height } = window;
  const [cameraState, setCameraState] = useState<"image" | "video">("image");
  const {
    webcamRef,
    capturing,
    handleStartCaptureClick,
    handleStopCaptureClick,
  } = useRecordVideo(onCapture);
  const { OpenSettingsModal, requestPermission, permissionAllowed } =
    useCameraPermissions();
  useEffect(() => {
    const body = document.querySelector("body");
    if (open) {
      requestPermission(true, true);
      if (body) body.style.overflow = "hidden";
    } else {
      if (body) body.style.overflow = "auto";
    }
  }, [open]);

  return (
    <>
      <OpenSettingsModal />
      {open && permissionAllowed ? (
        <Portal>
          <S.Container>
            <S.TabsWrapper>
              <ToggleButtonGroup
                value={cameraState}
                exclusive
                onChange={(_e: any, newValue: any) => {
                  if (newValue) setCameraState(newValue);
                }}
              >
                <ToggleButton value="image">
                  <CameraAltIcon />
                </ToggleButton>
                <ToggleButton value="video">
                  <VideocamIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </S.TabsWrapper>
            {capturing ? (
              <S.StyledIconButton
                onClick={() => {
                  handleStopCaptureClick();
                }}
              >
                <S.StopRecordingIcon />
              </S.StyledIconButton>
            ) : (
              <S.StyledIconButton
                onClick={() => {
                  if (cameraState === "image")
                    onCapture(
                      webcamRef.current?.getScreenshot?.(),
                      cameraState
                    );
                  else handleStartCaptureClick(new Date());
                }}
              >
                <RadioButtonCheckedIcon
                  style={{ width: "60px", height: "60px", color: "white" }}
                />
              </S.StyledIconButton>
            )}
            {capturing && <Timer />}
            <S.CloseButton
              onClick={() => {
                handleStopCaptureClick();
                onClose();
              }}
            >
              <CloseIcon
                style={{ width: "24px", height: "24px", color: "white" }}
              />
            </S.CloseButton>
            <Webcam
              ref={webcamRef}
              audio={true}
              height={height}
              width={width}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                aspectRatio: width / height,
                facingMode: "environment",
              }}
            ></Webcam>
          </S.Container>
        </Portal>
      ) : null}
    </>
  );
};
