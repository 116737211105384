import { IconButton } from "@mui/material";
import { styled } from "@mui/system";

export const CardContainer = styled("div")<{ $variant: string }>(
  ({ theme, $variant }) => `
  max-height: 100vh;
  overflow-y: auto;
  padding: ${theme.spacing(2)};
  width: 100%;
  height: 100%;
  background-color: ${theme.palette?.surface.main};
  justify-content: flex-start;
  border-radius: 6px;
  box-sizing: border-box;
  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(4)};
    height: auto;
    max-width: 570px;
    border: none;
    justify-content: center;
    border: 1px solid ${theme.palette.borders.primary};
  }

  ${
    $variant === "secondary" &&
    `
      width: auto;
      height: auto;
      justify-content: center;
      margin: ${theme.spacing(2)};
      border: 1px solid ${theme.palette.borders.primary};
      overflow-y: initial;
    `
  }

    ${
      $variant === "tertiary" &&
      `
      width: 100%;
      height: auto;
      border: none;
      padding: ${theme.spacing(2)};
      ${theme.breakpoints.up("sm")} {
        padding: ${theme.spacing(2)};
        border: none;
      }
    `
    }
`
);

export const ButtonsContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
);

export const ImageContainer = styled("div")(
  ({ theme }) => `
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`
);

export const BackButton = styled(IconButton)(
  ({ theme }) => `
  padding: 0;
`
);
