import { Chip } from "@mui/material";
import { styled } from "@mui/system";

export const StyledChipButton = styled(Chip)(
  ({ theme, variant }) => `
  &, &:hover {
    background-color: ${
      variant === "filled" ? theme.palette.primary.main : "transparent"
    };
    border-color: ${theme.palette.borders.primary};
    color: ${
      variant === "filled"
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main
    };
    padding: ${theme.spacing(1)} ${theme.spacing(0.5)};
    min-height: 40px;
    border-radius: 100px;
    font-size: ${(theme.typography as any).body2.fontSize};
  }
  
  `
);

export const Container = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing(1)};
`
);
