import { IconProps, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/system";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const Container = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
`
);

export const MediaContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(1)};
`
);

export const TextContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
`
);

export const SeverityText = styled(Typography)<
  TypographyProps & { $severity: "HIGH" | "MEDIUM" | "LOW" }
>(
  ({ theme, $severity }) => `
  color: ${theme.palette.severity[$severity]};
`
);

export const SeverityIcon = styled(WarningAmberIcon)<
  { $severity: "HIGH" | "MEDIUM" | "LOW" } & IconProps
>(
  ({ theme, $severity }) => `
  color: ${theme.palette.severity[$severity]};
  width: 14px;
  height: 14px;
`
);
