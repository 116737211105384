import { useStores } from "@checkfirst-ltd/checkfirst-customer-common";
import * as S from "./styled";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Alert, Snackbar, Typography } from "@mui/material";

interface Props {
  visible: boolean;
  message: string;
  onDismiss: () => void;
}

export const ErrorToaster = ({ visible, message, onDismiss }: Props) => {
  return (
    <Snackbar open={visible} autoHideDuration={3000} onClose={onDismiss}>
      <S.StyledAlert onClose={onDismiss} severity="error" variant="filled">
        {message}
      </S.StyledAlert>
    </Snackbar>
  );
};

export const StatefulSnackbar = observer(() => {
  const { snackbarStore } = useStores();
  if (snackbarStore.state === "error") {
    return (
      <ErrorToaster
        visible={snackbarStore.visible}
        message={snackbarStore.message}
        onDismiss={() => snackbarStore.onDismiss()}
      />
    );
  }
  return null;
});
