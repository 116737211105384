import * as React from "react";
import { observer } from "mobx-react-lite";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import { DrawerListItem } from "src/components/UIComponents/DrawerListItem";
import {
  UIComponentProps,
  useLocalization,
  useStores,
} from "@checkfirst-ltd/checkfirst-customer-common";
import * as S from "./styled";
import { SvgIcon, Typography, useMediaQuery } from "@mui/material";
import SideNavIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/SideNavIcon.svg";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { PrimaryButton } from "src/components/UIComponents/Buttons";

const drawerWidth = 340;

export const SideNavigation: React.FC<UIComponentProps> = ({
  open,
  title,
  selectedCategoryId,
  categories,
  onCollapse,
  onBackClick,
  onItemSelected,
  onSubmitClick,
}) => {
  const { t } = useLocalization();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <S.StyledDrawer
      variant={isMobile ? "temporary" : "persistent"}
      anchor="left"
      open={open}
      style={{
        width: isMobile ? "100%" : 340,
      }}
    >
      <S.StyledDrawerHeader>
        <S.TitleContainer>
          <IconButton onClick={onBackClick}>
            <ChevronLeftIcon style={{ width: "24px", height: "24px" }} />
          </IconButton>
          <Typography variant="h5">{title}</Typography>
        </S.TitleContainer>
        {isMobile && (
          <S.StyledIcon onClick={() => onCollapse()}>
            <SvgIcon component={SideNavIcon as any} />
          </S.StyledIcon>
        )}
      </S.StyledDrawerHeader>
      {categories.map((e, index) => (
        <S.ItemContainer
          onClick={() => {
            onItemSelected(e.id);
            if (isMobile) onCollapse();
          }}
          key={e.id}
          $selected={selectedCategoryId === e.id}
        >
          <S.ItemIcon>
            {/* <Text>{e.state}</Text> */}
            {e.state === "error" && (
              <S.ErrorBadge>
                <PriorityHighOutlinedIcon
                  style={{ width: "16px", height: "16px" }}
                />
              </S.ErrorBadge>
            )}
            {e.state === "success" && (
              <S.SuccessBadge>
                <CheckOutlinedIcon style={{ width: "16px", height: "16px" }} />
              </S.SuccessBadge>
            )}
            {!e.state && <S.IndexBadge>{index + 1}</S.IndexBadge>}
          </S.ItemIcon>
          <S.ItemTitleContainer>
            <Typography variant="body1" fontWeight={500}>
              {e.name}
            </Typography>
            <S.Subtitle variant="body2">{e.subtitle}</S.Subtitle>
          </S.ItemTitleContainer>
          <ChevronRightIcon
            style={{ width: "24px", height: "24px", marginLeft: "auto" }}
          />
        </S.ItemContainer>
      ))}

      <S.ButtonWrapper>
        <PrimaryButton
          variant="contained"
          fullWidth
          startIcon={<SvgIcon component={FileUploadIcon as any} />}
          onClick={() => onSubmitClick?.()}
        >
          {t("sideNavigation.submitCheckButton")}
        </PrimaryButton>
      </S.ButtonWrapper>
    </S.StyledDrawer>
  );
};
