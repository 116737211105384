import { styled, Typography } from "@mui/material";

export const Subtitle = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.subtitleColor?.main};
`
);

export const MediaContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`
);

export const ButtonsContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(1)};
`
);