import {
  LANGUAGE_MAP,
  SupportedLanguages,
} from "@checkfirst-ltd/checkfirst-customer-common";
import * as S from "./styled";
import { useState } from "react";
import { BottomActionSheet } from "src/components/UIComponents/BottomActionSheet";
import { IconButton } from "@mui/material";
import TranslateIcon from "@checkfirst-ltd/checkfirst-customer-common/dist/assets/Icons/TranslateOutlined.svg";

interface Props {
  onTranslatePress: (key: SupportedLanguages) => void;
}

export const TranslationSheet = ({ onTranslatePress }: Props) => {
  const [translateOpen, setTranslateOpen] = useState(false);
  return (
    <>
      <S.StyledButton onClick={() => setTranslateOpen(true)} color="primary">
        <TranslateIcon />
      </S.StyledButton>
      <BottomActionSheet
        open={translateOpen}
        onClose={() => setTranslateOpen(false)}
      >
        {Object.keys(LANGUAGE_MAP).map((key) => (
          <S.MenuItem
            onClick={() => {
              onTranslatePress(key as SupportedLanguages);
              setTranslateOpen(false);
            }}
          >
            {LANGUAGE_MAP[key as SupportedLanguages]}
          </S.MenuItem>
        ))}
      </BottomActionSheet>
    </>
  );
};
