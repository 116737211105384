import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SessionTrace } from "@newrelic/browser-agent/features/session_trace";
import { SessionReplay } from "@newrelic/browser-agent/features/session_replay";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
const options = {
  info: {
    applicationID: +(process.env.REACT_APP_NEW_RELIC_APP_ID ?? ""),
    beacon: "bam.eu01.nr-data.net",
    errorBeacon: "bam.eu01.nr-data.net",
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
    sa: 1,
  },
  init: {
    session_replay: {
      enabled: true,
      block_selector: "",
      mask_text_selector: "*",
      sampling_rate: 10.0,
      error_sampling_rate: 100.0,
      mask_all_inputs: true,
      collect_fonts: true,
      inline_images: false,
      inline_stylesheet: true,
      mask_input_options: {},
    },
    session_trace: {
      enabled: true,
    },
    ajax: {
      deny_list: ["bam.eu01.nr-data.net"],
    },
    distributed_tracing: {
      enabled: true,
    },
    privacy: {
      cookies_enabled: true,
    },
  },
  loader_config: {
    accountID: +(process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID ?? ""),
    agentID: +(process.env.REACT_APP_NEW_RELIC_APP_ID ?? ""),
    applicationID: +(process.env.REACT_APP_NEW_RELIC_APP_ID ?? ""),
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
    trustKey: +(process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID ?? ""),
  },
  features: [SessionTrace, SessionReplay],
};

// The agent loader code executes immediately on instantiation.
new BrowserAgent(options);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
